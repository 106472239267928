// one auth controller to rule them all
const APIConfig = require("apiconfig");
const Marionette = require("backbone.marionette");

const Sentry = require("@sentry/browser");
const $ = require("jquery");
const _ = require("underscore");
const parseURI = require("parse-uri");
const defaultJSONHeaders = require("../helpers/default_json_headers");
const {
  getIsSetup2FARequired,
  setIs2FASetupRequired,
} = require("../helpers/setup2FARequired");
const {
  getBrowserFingerprintHeader,
} = require("../helpers/browserFingerprint");
const {
  getIsPasswordResetRequired,
  setIsPasswordResetRequired,
} = require("../helpers/passwordResetRequired");
const {
  setSetup2FARequiredCheckpoint,
  Setup2FARequiredCheckpoint,
} = require("../helpers/setup2FARequiredCheckpoint");
const {
  formRedirectToUrl,
  formRouteWithRedirectToQueryParams,
} = require("../helpers/navigation");
const { redirectToOAuthAuthorize } = require("../pages/OAuth/helpers");
const { IMPERSONATING_USERNAME_COOKIE } = require("../constants");
const Cookies = require("js-cookie");
const logger = require("../logger");

const MAKO_COOKIE_NAME = "mako_auth_token";
const SESSION_COOKIE_NAME = "session_identifier";

module.exports = Marionette.Controller.extend({
  initialize(options) {
    this.configureClient = options.configureClient;
    this.login = options.login;
    this.redirectCallback = options.redirectCallback || function () {};
    this.callApi = options.callApi;
    this.checkIfUserHasAccountWithAuth0Email =
      options.checkIfUserHasAccountWithAuth0Email;
    this.tiara = options.tiara;
    this.scopes = {};
    this.setSessionCookie();
  },

  authenticate(username, password, isInOauth = false) {
    // Set mako_auth_token upon successful login
    return this.authenticateToken(username, password).success(
      // eslint-disable-next-line camelcase
      ({
        token,
        setup_2fa_required = false,
        password_reset_required = false,
      }) => {
        this.setToken(token);
        defaultJSONHeaders.updateMakoAuthCookieHeader();
        // This value is used by the security checkup to show the optional
        // or required 2FA setup flows.
        setIs2FASetupRequired(setup_2fa_required);
        // Store password reset flag in case user has to go through 2FA first,
        // which will take them through to validate_2fa.js
        setIsPasswordResetRequired(password_reset_required);

        return this.redirectAfterLogin(
          token,
          setup_2fa_required,
          password_reset_required,
          isInOauth,
          false
        );
      }
    );
  },

  redirectAfterLogin(
    token,
    setup2FARequired,
    passwordResetRequired,
    redirectToOauth,
    skipSecurityCheckup
  ) {
    if (setup2FARequired) {
      // set cookie to persist setup 2fa requirement
      setSetup2FARequiredCheckpoint(Setup2FARequiredCheckpoint.EMAIL);

      this.redirectToSetup2FARequiredEmailCheckpoint();
      return $.Deferred().resolve();
    }

    // If the user is not required to setup 2FA, we need to determine
    // if they already have 2FA enabled or are just exempt from the requirement.
    // If they don't have 2FA enabled, then check if they need to reset their password.

    return this.fetch2FASetting(token)
      .done((mfaSetting) => {
        if (mfaSetting && mfaSetting.is_verified) {
          this.redirectToRouteWithQueryParams("validate_2fa");
          return;
        } else if (passwordResetRequired) {
          this.redirectToPasswordReset();
          return;
        } else if (redirectToOauth) {
          redirectToOAuthAuthorize();
          return;
        }

        if (skipSecurityCheckup) {
          this.redirectToGuide();
        } else {
          this.redirectToSecurityCheckup();
        }
      })
      .fail(() => {
        // In all error cases, either redirect to password reset (if required)
        // or redirect to the security checkup.
        // The security checkup will check the 2FA status again
        // and render the appropriate flow based on the result.
        if (passwordResetRequired) {
          return this.redirectToPasswordReset();
        } else if (redirectToOauth) {
          return redirectToOAuthAuthorize();
        }
        // if in oauth flow redirect to oauth
        if (skipSecurityCheckup) {
          this.redirectToGuide();
        } else {
          this.redirectToSecurityCheckup();
        }
      })
      .always(() => $.Deferred().resolve());
  },

  async authCallback(queryParams) {
    // Wait for the after auth redirect to complete and then run this to fetch
    // the new token and keep going.
    const targetUrl = await this.afterAuthRedirect(queryParams);
    const fetchResponse = await this.fetchToken();

    if (fetchResponse.status === 401) {
      const userEmailExists = await this.checkIfUserHasAccountWithAuth0Email();
      if (userEmailExists) {
        this.redirectToLogin();
      } else {
        this.redirectToSignup();
      }
      return;
    }

    const {
      token,
      setup_2fa_required = false,
      password_reset_required = false,
    } = await fetchResponse.json();

    if (!token) {
      return;
    }

    this.setToken(token);
    defaultJSONHeaders.updateMakoAuthCookieHeader();
    // This value is used by the security checkup to show the optional
    // or required 2FA setup flows.
    setIs2FASetupRequired(setup_2fa_required);
    // Store password reset flag in case user has to go through 2FA first,
    // which will take them through to validate_2fa.js
    setIsPasswordResetRequired(password_reset_required);

    return this.redirectAfterLogin(
      token,
      setup_2fa_required,
      password_reset_required,
      false,
      true
    );
  },

  fetchToken() {
    return this.callApi(`${APIConfig.host}sessions`, {
      method: "POST",
    });
  },

  authenticateToken(username, password) {
    return $.ajax({
      type: "POST",
      url: `${APIConfig.host}public/tokens`,
      contentType: "application/json",
      data: JSON.stringify({
        username,
        password,
      }),
    });
  },

  afterAuthRedirect(queryParams) {
    if (this.redirectCallback !== undefined) {
      return this.redirectCallback(queryParams);
    }
    return $.Deferred().promise();
  },

  fetch2FASetting(token) {
    return $.ajax({
      type: "GET",
      url: `${APIConfig.host}public/access_settings/multifactor`,
      contentType: "application/json",
      headers: {
        Authorization: `token ${token}`,
      },
    });
  },

  redirectToRouteWithQueryParams(route) {
    const parsedUri = parseURI(this.getWindowLocation());
    const redirectToQueryParam = parsedUri.queryKey.redirect_to;

    const url = formRouteWithRedirectToQueryParams(route, redirectToQueryParam);
    this.redirect(url);
  },

  redirectToSecurityCheckup() {
    this.redirectToRouteWithQueryParams("security_checkup");
  },

  redirectToPasswordReset() {
    this.redirectToRouteWithQueryParams("password_reset_required");
  },

  redirectToSetup2FARequiredEmailCheckpoint() {
    this.redirectToRouteWithQueryParams("2fa_email_checkpoint");
  },

  redirectToGuide() {
    const isFeatureBranch = process.env.publicPath !== "/";
    const guidePath = isFeatureBranch
      ? `${process.env.publicPath}guide`
      : "/guide";
    this.redirect(guidePath);
  },

  redirectToSignup() {
    window.location.href = `${APIConfig.signup_host}/unified_login/signup`;
  },

  redirectToLogin() {
    window.location.href = `${APIConfig.login_host}/login/identifier`;
  },

  setToken(token) {
    // prevent karma (runs on http protocol) tests from failing
    const cookieOptions = {};

    if (process.env.NODE_ENV === "production") {
      cookieOptions.secure = true;
    }

    // set cookie then redirect to dashboard
    $.cookie(MAKO_COOKIE_NAME, token, cookieOptions);
    this.resetSessionCookie();
  },

  loginWithToken(token) {
    // If a user is logging in using a token (KASI Impersonation and Reseller team uses this),
    // we always bypass 2fa setup. In order to ensure local storage is in the correct state and
    // not influenced by a different login session, we set the required flag to false.
    setIs2FASetupRequired(false);

    this.setToken(token);

    const parsedUri = parseURI(this.getWindowLocation());
    const url = formRedirectToUrl(parsedUri.queryKey.redirect_to);
    this.redirect(url);
  },

  async loginWithAuth0Display(options) {
    await this.configureClient();
    await this.login(options);
  },

  checkAuthToken() {
    return !!Cookies.get(MAKO_COOKIE_NAME);
  },

  // hide all elements based on user scopes
  deny(view) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    function authorize($elt, scopes) {
      const formElements = "input,select,textarea,button";
      const classes = {
        ".btn": "is-disabled",
        ".switch": "is-switch-disabled",
      };

      for (let i = 0; i < scopes.length; i++) {
        const scope = scopes[i].trim();

        // hide element if user has a given scope and element is set to hide
        if (self.scopes[scope] && $elt.data("authorized") === "hide") {
          $elt.addClass("hidden");
          break;
        }

        // hide all user doesn't have scopes for
        if (!self.scopes[scope]) {
          // disable form fields
          $elt
            .find(formElements)
            .addBack(formElements)
            .attr("disabled", "disabled")
            .addClass("is-disabled");

          // visually disable elements that look like buttons or toggles
          Object.keys(classes).forEach((selector) => {
            $elt.find(selector).addBack(selector).addClass(classes[selector]);
          });

          $elt.find("a").addClass("hidden");

          if ($elt.data("unauthorized") === "hide") {
            $elt.addClass("hidden");
          }

          $elt.find("[data-unauthorized=hide]").addClass("hidden");
          $elt.trigger("mako:unauthorized", scope);

          break;
        }
      }
    }

    // find all child elements with data-permissions
    view.$el
      .find("[data-permissions]")
      .each(function findChildElementsWithDataPermissions() {
        const $elt = $(this);
        let perms = $elt.attr("data-permissions").split(/,/);

        // find any parents that also require scopes
        $elt
          .parents("[data-permissions]")
          .each(function findParentsRequiringScopes() {
            perms = perms.concat($(this).attr("data-permissions").split(/,/));
          });

        // authorize on the element's scopes and parent's scopes
        authorize($elt, perms);
      });
  },

  logout(clickedLogout) {
    this.tiara.defaultLogout(clickedLogout);
  },

  setSessionCookie() {
    if (typeof Cookies.get(SESSION_COOKIE_NAME) === "undefined") {
      // Generates a fairly long random identifier for the session
      // This cookie doesn't matter for auth and is used only to identify session in 3rd party integs
      const sessionCookie = `${(Math.random() * 1e16).toString(36)}-${(
        Math.random() * 1e16
      ).toString(36)}-${(Math.random() * 1e16).toString(36)}`;
      Cookies.set(SESSION_COOKIE_NAME, sessionCookie);
    }
  },

  getSessionCookie() {
    return Cookies.get(SESSION_COOKIE_NAME);
  },

  unsetSessionCookie() {
    Cookies.remove(SESSION_COOKIE_NAME);
  },

  resetSessionCookie() {
    this.unsetSessionCookie();
    this.setSessionCookie();
  },

  redirectTo2FA() {
    // This code is duplicated in Tiara with the login redirect stuff it would be nice to consolidate it
    const redirectTo = encodeURIComponent(
      window.location.pathname + window.location.search
    );
    this.redirect(`${APIConfig.root}validate_2fa?redirect_to=${redirectTo}`);
  },

  setPrefilter() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    $.ajaxPrefilter((options, originalOptions, jqXHR) => {
      // only if request is api.sendgrid.com
      if (options.url.indexOf(APIConfig.host) === 0) {
        const token = Cookies.get(MAKO_COOKIE_NAME);
        const validate2FARoute = `${process.env.publicPath}validate_2fa`;
        jqXHR.fail((response) => {
          if (response.status === 401 && token) {
            self.logout();
          } else if (
            response.status === 403 &&
            token &&
            response.responseJSON.errors &&
            response.responseJSON.errors.length > 0 &&
            response.responseJSON.errors[0].field === "2fa" &&
            self.getCurrentRoute() !== validate2FARoute
          ) {
            self.redirectTo2FA();
          }
        });

        jqXHR.setRequestHeader("Authorization", `token ${token}`);
        // skipImpersonation is set in tiara, needed for subuser searching when impersonating
        const impersonatingUsername = $.cookie(IMPERSONATING_USERNAME_COOKIE);
        if (impersonatingUsername && !options.skipImpersonation) {
          jqXHR.setRequestHeader("On-behalf-of", impersonatingUsername);
        }

        // Add the EHawk Talon (browser fingerprint) header on all requests. Used for anti-abuse downstream.
        const browserFingerprint = getBrowserFingerprintHeader();
        if (browserFingerprint) {
          jqXHR.setRequestHeader("Browser-Fingerprint", browserFingerprint);
        }
      }
    });
  },

  getCurrentRoute() {
    // Backbone.history hasn't started yet, so manually get route
    return window.location.pathname;
  },

  getScopes() {
    // Used on api keys create and edit permissions views
    return this.scopes;
  },

  verify(scope) {
    // Accepts an array or a single scope
    if (Array.isArray(scope)) {
      return scope
        .map((oneScope) => _.has(this.scopes, oneScope))
        .every((hasAccess) => hasAccess);
    }
    return _.has(this.scopes, scope);
  },

  is2faSetupRequired() {
    return getIsSetup2FARequired();
  },

  isPasswordResetRequired() {
    return getIsPasswordResetRequired();
  },

  // private
  getWindowLocation() {
    return window.location;
  },

  redirect(url) {
    window.parent.location.href = url;
  },

  defaultLogout(clickedLogout) {
    if (this.tiara !== undefined) {
      this.tiara.defaultLogout(clickedLogout);
    }
  },
});
