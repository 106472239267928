import { ActionsObservable, ofType } from 'redux-observable';
import { switchMap, mergeMap, map } from 'rxjs/operators';
import { from, forkJoin, Observable } from 'rxjs';
import { ActionTypes } from './actionTypes';
import * as CancelAccountDiffActions from './actions';
import { OES } from '../middle_tier/services/oes/service';
import { OesAccountDto } from '../middle_tier/services/oes/dtos';
import { shouldShowFreeTrialInConsole } from 'src/helpers/shouldShowFreeTrialInConsole';
import { Slot } from '../types/slot';

export const cancelAccountDiffEpic = (
  action$: ActionsObservable<
    ReturnType<typeof CancelAccountDiffActions.Actions.cancelAccountDiffRequest>
  >
): Observable<
  ReturnType<typeof CancelAccountDiffActions.Actions.cancelAccountDiffSuccess>
> => {
  return action$.pipe(
    ofType(ActionTypes.CancelAccountDiffRequest),
    switchMap(() => {
      const { catalog_org = 'sg' } = window.Reqres.request('camAccount');

      // Before free trials release the offerings we put the customer on
      // when they cancel are EI / MC free, after its EI / MC end of trial.
      const eiOffering = from(
        shouldShowFreeTrialInConsole()
          ? OES.getEndOfTrialOffering(catalog_org, Slot.EI)
          : OES.getFreeOffering(catalog_org, Slot.EI)
      );
      const mcOffering = from(
        shouldShowFreeTrialInConsole()
          ? OES.getEndOfTrialOffering(catalog_org, Slot.MC)
          : OES.getFreeOffering(catalog_org, Slot.MC)
      );

      return forkJoin([eiOffering, mcOffering]).pipe(
        mergeMap(([freeEI, freeMC]) => {
          const offerings = [{ name: freeEI.name }, { name: freeMC.name }];
          return from(OES.getNewPlanAccountDiff({ offerings }));
        }),
        map((cancelAccountDiff: OesAccountDto) => {
          return CancelAccountDiffActions.Actions.cancelAccountDiffSuccess(
            cancelAccountDiff
          );
        })
      );
    })
  );
};

export default [cancelAccountDiffEpic];
