const Backbone = require("backbone");
const APIConfig = require("apiconfig");
const _ = require("underscore");
const $ = require("jquery");
const StatsModel = require("../models/stats_model");
const moment = require("moment");
// eslint-disable-next-line no-unused-vars
const momentRange = require("moment-range");
const snakeCase = require("templates/helpers/snakeCase");

/* eslint-disable */

module.exports = Backbone.Collection.extend({
  model: StatsModel,

  names: [
    "requests",
    "delivered",
    "opens",
    "unique_opens",
    "clicks",
    "unique_clicks",
    "unsubscribes",
    "bounces",
    "spam_reports",
    "blocks",
    "bounce_drops",
    "spam_report_drops",
    "unsubscribe_drops",
    "invalid_emails",
  ],
  path: "stats",

  url() {
    return this.decorateUrl(APIConfig.host + this.path);
  },

  initialize() {
    this.url_params = {
      limit: 100,
      offset: 0,
      aggregated_by: "day",
    };

    this.computations = [];
  },

  addComputation(numerator, denominator, description) {
    this.computations.push({ numerator, denominator, description });
  },

  params() {
    let params;

    // if 2 args were passed in, assume it is key and value
    if (arguments.length === 2) {
      params = {};
      params[arguments[0]] = arguments[1];
    } else if (arguments.length === 1) {
      // if one arg is passed in, assume it is a hash of params
      params = arguments[0];
    } else {
      // else explode
      throw "expected key and value or hash to be passed";
    }

    this.url_params = $.extend(this.url_params, params);
  },

  param(key) {
    return this.url_params[key];
  },

  decorateUrl(endpoint) {
    const params = _.clone(this.url_params);
    Object.keys(params).forEach((key) => {
      if (params[key] === null || params[key] === undefined) {
        delete params[key];
      }
    });

    return `${endpoint}?${$.param(params, true)}`;
  },

  withName(name) {
    const result = this.clone();
    if (!name) {
      return result;
    }

    const items = [];
    _.each(result.models, (model) => {
      const m = model.clone();
      const stats = m.get("stats");
      const filtered = [];
      _.each(stats, (stat) => {
        if (stat.name === name) {
          filtered.push(stat);
        }
      });

      m.set("stats", filtered);
      items.push(m);
    });

    result.reset(items);

    return result;
  },

  parse(data) {
    this.hasStats = true;
    if (this.aggField === undefined) {
      // If no aggregation field is set then default to raw data. (by date)
      return data;
    }

    const { aggField } = this;

    if (!Array.isArray(data)) {
      data = [data];
    }

    let res = _.map(data, (dateItem) => {
      // Look for aggField at the root of the object
      const outerKey = dateItem[aggField];

      const date = dateItem.date;
      // Look for the aggField within the stats field
      return _.map(dateItem.stats, (statItem) => {
        let key = outerKey;
        if (!key) {
          key = statItem[aggField];
        }

        return { key, date, value: statItem.metrics };
      });
    });

    // Flatten and reduce results to a single object
    res = _.flatten(res).reduce((aggregatedObject, item) => {
      // For ESPs each item.key is an ESP
      if (!(item.key in aggregatedObject)) {
        aggregatedObject[item.key] = item.value;
        return aggregatedObject;
      }

      Object.keys(item.value).forEach((metric) => {
        aggregatedObject[item.key][metric] += item.value[metric];
      });

      return aggregatedObject;
    }, {});

    // Put object in correct structure
    // { stats: [{key: gmail, metrics: { requests: 123, ... }}] }
    return _.map(res, (v, k) => ({
      stats: [{ metrics: v, key: k }],
    }));
  },

  getNames(metric, limit) {
    let data = [];

    if (metric) {
      data = this.getRankedList(metric);
      if (limit) {
        data = data.slice(0, limit);
      }
    } else {
      data = _.map(this.names, (n) => ({ statName: n }));
    }
    return _.map(data, (d) => ({
      key: snakeCase(d.statName),
      label: d.statName,
    }));
  },

  graphData(metric, hiddenMetrics) {
    // Get the raw data from the models
    // [models] -> [objects]
    const data = _.map(this.models, (d) => ({
      start: d.get("date"),
      stats: d.get("stats"),
    }));

    // Spread the dates throughout the stats
    // [{start: '2014-12-12', stats: [statsArray]} ...]
    //  -> [[[name:statName, start: '2014-12-12', value:2000]...] ...]
    let spread = [];
    if (metric) {
      spread = _.map(data, (d) => {
        const date = d.start;
        return _.reduce(
          d.stats,
          (list, s) => {
            list.push({ name: s.name, start: date, value: s.metrics[metric] });
            return list;
          },
          []
        );
      });
    } else {
      spread = _.map(data, (d) => {
        const date = d.start;
        if (d.stats.length === 0) {
          return [];
        }

        return _.map(d.stats[0].metrics, (v, m) => ({
          value: v,
          name: m,
          start: d.start,
        }));
      });
    }

    // Flatten the data
    // [[[name:statName, start: '2014-12-12', value:2000]...] ...]
    // -> [[name:statName, start: '2014-12-12', value:2000] ...]
    const flattenedStats = _.flatten(spread);

    // Combine data by name
    // [[name:statName, start: '2014-12-12', value:2000] ...]
    // -> {blocks: {'2014-12-12': 2000, '2014-12-13': 10}, clicks: ... }
    const combinedStats = _.reduce(
      flattenedStats,
      (obj, d) => {
        if (!obj[d.name]) {
          obj[d.name] = {};
        }
        obj[d.name][d.start] = d.value;
        return obj;
      },
      {}
    );

    // Convert all names to lower case and spaces between words to underscores
    const snakeCaseCombinedStats = {};
    _.each(combinedStats, (value, key) => {
      key = snakeCase(key);
      snakeCaseCombinedStats[key] = value;
    });

    // Convert object to array of objects
    // {blocks: {'2014-12-12': 2000, '2014-12-13': 10}, clicks: ... }
    // -> [{type: blocks,
    //      data: {'2014-12-12': 2000, '2014-12-13': 10}},
    //     {type: clicks,
    //      data: {'2014-12-12': 3000, '2014-12-13': 5}}
    //    ]
    const statsList = [];
    Object.keys(snakeCaseCombinedStats).forEach((key) => {
      statsList.push({
        type: key,
        hidden: false,
        data: snakeCaseCombinedStats[key],
      });
    });

    const end = moment(this.param("end_date"));
    const start = moment(this.param("start_date"));

    const aggregatedBy = this.param("aggregated_by");

    if (aggregatedBy === "week") {
      start.startOf("isoweek");
    }
    if (aggregatedBy === "month") {
      start.startOf("month");
    }

    // Use only names specified in this collection
    let visibleData = statsList;
    if (!metric) {
      const metrics = this.names;
      visibleData = _.filter(visibleData, (d) => _.contains(metrics, d.type));
    }

    // Set Hidden attribute to true
    Object.keys(visibleData).forEach((key) => {
      if (_.contains(hiddenMetrics, visibleData[key].type)) {
        visibleData[key].hidden = true;
      }
    });

    // Add dates with no data
    moment.range(start, end).by(`${aggregatedBy}s`, (t) => {
      t = t.format("YYYY-MM-DD");
      _.map(visibleData, (statObj) => {
        if (!statObj.data[t]) {
          statObj.data[t] = 0;
        }
      });
    });

    // Convert data objects {date: value} into array [date, value]
    _.map(visibleData, (vd) => {
      vd.data = _.pairs(vd.data);
    });

    // Sort the data by time
    const chronologicalVisibleData = _.map(visibleData, (vd) => ({
      type: vd.type,
      hidden: vd.hidden,
      data: _.sortBy(vd.data, (d) => moment(d[0]).unix()),
    }));

    if (metric) {
      return this.rankData(chronologicalVisibleData).slice(0, 10);
    }
    return chronologicalVisibleData;
  },

  rankedNames(data) {
    const rankedData = this.rankData(data);
    return _.map(rankedData, (d) => d.type);
  },

  // Get list of rankings based on name (e.g: esp names: gmail, yahoo...) sorted by totals.
  rankData(data) {
    const aggregatedData = _.map(data, (d) => ({
      type: d.type,
      hidden: d.hidden,
      data: d.data,
    }));

    return _.sortBy(
      aggregatedData,
      (d) => -_.reduce(d.data, (m, d) => m + d[1], 0)
    );
  },

  aggregateRemainder(remainderKeyVals) {
    return _.reduce(
      remainderKeyVals,
      (remainingObj, currentObj) => {
        remainingObj.total += currentObj.total;
        return remainingObj;
      },
      { statName: "remaining", total: 0 }
    );
  },

  getRankedList(metric) {
    const stats = _.map(this.models, (d) => d.get("stats"));
    const flattenedStats = _.flatten(stats);
    const combinedStats = _.reduce(
      flattenedStats,
      (obj, d) => {
        if (!obj[d.name]) {
          obj[d.name] = 0;
        }
        obj[d.name] += d.metrics[metric];
        return obj;
      },
      {}
    );
    const statsList = [];
    Object.keys(combinedStats).forEach((key) => {
      statsList.push({ statName: key, total: combinedStats[key] });
    });
    return _.sortBy(statsList, (d) => -d.total);
  },

  topNWithRemainder(n, metric) {
    const rankedList = this.getRankedList(metric);
    const topN = rankedList.slice(0, n);
    if (rankedList.length > n) {
      const remainderKeyVals = _.rest(rankedList, n);
      const aggregatedRemainder = this.aggregateRemainder(remainderKeyVals);
      topN.push(aggregatedRemainder);
    }
    return topN;
  },

  getStats() {
    const results = {};

    results.requestsTotal = this.reduce(
      (sum, model) => sum + model.get("stats")[0].metrics.requests,
      0
    );

    _(this.names)
      .chain()
      .reject((k) => k === "requests")
      .each(function eachStatTotal(key) {
        const total = this.reduce(
          (sum, model) => sum + model.get("stats")[0].metrics[key],
          0
        );

        results[`${key}Total`] = total;
        results[`${key}Percentage`] = total / results.requestsTotal;
      }, this);

    return results;
  },

  getComparisonStats() {
    const results = [];

    // flattens stats to look like:
    // [{"name": "value", "type": "value", "metrics": {...}}, .... ]
    const flattenedStats = _.flatten(
      _.map(this.models, (model) => model.get("stats"))
    );

    _.each(this.names, (metricName) => {
      const metric = {
        metric: metricName,
      };

      _.each(flattenedStats, (stat) => {
        const key = stat.name;
        if (metric[key]) {
          metric[key] += stat.metrics[metricName];
        } else {
          metric[key] = stat.metrics[metricName];
        }
      });

      results.push(metric);
    });

    return results;
  },

  getComparisonByMetricStats(metric) {
    // returns something like:
    // [
    //    {"date": "2014-11-01", "Shane": 1234, "Robert": 1234},
    //    {"date": "2014-11-02", "Shane": 234, "Robert": 234},
    //    ...
    // ]
    return _.map(this.models, (model) => {
      const newModel = {
        date: model.get("date"),
      };

      _.each(model.get("stats"), (stat) => {
        newModel[stat.name] = stat.metrics[metric];
      });

      return newModel;
    });
  },

  getStatsByMetric(metric) {
    // returns something like:
    // [
    //    {"date": "2014-11-01", "received": 1234},
    //    {"date": "2014-11-02", "received": 234},
    //    ...
    // ]
    return _.map(this.models, (model) => {
      const newModel = {
        date: model.get("date"),
      };

      _.each(model.get("stats"), (stat) => {
        newModel[metric] = stat.metrics[metric];
      });

      return newModel;
    });
  },
});
