import React, { useMemo } from 'react';
import { Alert } from '@twilio-paste/core/alert';
import { Text } from '@twilio-paste/core/text';
import { content } from '../content';

export interface Props {
  trialEndDate: string;
}

export const TrialEnded: React.VFC<Props> = ({ trialEndDate }) => {
  const text = useMemo(() => content.trialEnded(trialEndDate), [trialEndDate]);

  return (
    <Alert variant="neutral">
      <Text as="p">{text}</Text>
    </Alert>
  );
};
