// generated by coffee-script 1.9.2
/* eslint-disable */

let SendGridPalette;
const $ = require("jquery");

SendGridPalette = (function () {
  let balanceSubnavGenerator,
    bindAccountButtons,
    bindGravatars,
    bindQuickAdd,
    bindSideModal,
    bindStickySidebar,
    bindSwapper,
    convertSvgsToInline,
    dropdownify,
    getRandomColorForEmail,
    gravatarFallback,
    gravatarSuccess,
    hashCode,
    seededRandom,
    swapperClickEvent;

  convertSvgsToInline = function (selector) {
    if (selector === null) {
      selector = "img.svg";
    }
    return $(selector).each(function () {
      let $img, imgAttributes, imgURL;
      $img = $(this);
      imgURL = $img.attr("src");
      imgAttributes = $img[0].attributes;
      return $.get(imgURL, (data) => {
        let $svg, attr, j, len;
        $svg = $(data).find("svg");
        $svg = $svg.removeAttr("xmlns:a");
        for (j = 0, len = imgAttributes.length; j < len; j++) {
          attr = imgAttributes[j];
          if (attr.nodeName !== "src") {
            $svg.attr(attr.nodeName, attr.nodeValue);
          }
        }
        return $img.replaceWith($svg);
      });
    });
  };
  bindStickySidebar = function (selector) {
    let $sidebar, $window, sidebarYPos;
    if (selector === null) {
      selector = "nav.sidebar, nav.subnav";
    }
    $sidebar = $(selector);
    if ($sidebar.length) {
      sidebarYPos = $sidebar.offset().top;
      $window = $(window);
      return $window.scroll(
        (function (_this) {
          return function () {
            if ($window.scrollTop() > sidebarYPos) {
              return $sidebar.addClass("sticky");
            }
            return $sidebar.removeClass("sticky");
          };
        })(this)
      );
    }
  };
  balanceSubnavGenerator = function (selector) {
    if (selector === null) {
      selector = "nav.sidebar";
    }
    return function () {
      let $nav,
        $window,
        BUFFER,
        j,
        len,
        nav,
        ref,
        results,
        sidebarIsFixed,
        topPosition,
        windowHeight;
      BUFFER = 20;
      $window = $(window);
      windowHeight = $window.height();
      sidebarIsFixed = $(selector).css("position") === "fixed";
      ref = $(`${selector} > ul > li > .subnav`);
      results = [];
      for (j = 0, len = ref.length; j < len; j++) {
        nav = ref[j];
        $nav = $(nav);
        $nav.css({
          visibility: "hidden",
          display: "block",
        });
        $nav.removeClass("showup");
        if (sidebarIsFixed) {
          topPosition = $nav.parent().position().top;
        } else {
          topPosition = $nav.offset().top - $window.scrollTop();
        }
        if ($nav.height() + topPosition + BUFFER > windowHeight) {
          $nav.addClass("showup");
        }
        results.push(
          $nav.css({
            visibility: "",
            display: "",
          })
        );
      }
      return results;
    };
  };
  bindAccountButtons = function () {
    dropdownify(".account", ".avatar");
    dropdownify(".messages", ".icon");
    $("li.subuser").on("click", () => {
      $("ul.account-actions").hide();
      return $("ul.subuser-actions").show();
    });
    $("ul.subuser-actions li.previous").on("click", () => {
      $("ul.account-actions").show();
      return $("ul.subuser-actions").hide();
    });
    $("ul.notification-list li.notification").on("click", () => {
      $("ul.notification-list").hide();
      return $("ul.individual-notification-list").show();
    });
    return $("ul.individual-notification-list li.previous").on("click", () => {
      $("ul.notification-list").show();
      return $("ul.individual-notification-list").hide();
    });
  };
  dropdownify = function (mainSelector, iconSelector) {
    let $main, timeoutId;
    $main = $(mainSelector);
    timeoutId = null;
    $(`${mainSelector} ${iconSelector}`).on("click", () =>
      $main.addClass("active")
    );
    $(`${mainSelector} .nav-dropdown`).on("mouseleave", () => {
      let hideFunc;
      hideFunc = function () {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        return $main.removeClass("active");
      };
      timeoutId = setTimeout(hideFunc, 3000);
      return timeoutId;
    });
    return $(`${mainSelector} .nav-dropdown`).on("mouseenter", () => {
      if (timeoutId) {
        return clearTimeout(timeoutId);
      }
    });
  };
  swapperClickEvent = function (e) {
    return $(this)
      .find(".wrap")
      .toggleClass("show-second")
      .toggleClass("show-first");
  };
  bindQuickAdd = function (buttonSelector, viewSelector) {
    if (buttonSelector === null) {
      buttonSelector = "nav.sidebar .add";
    }
    if (viewSelector === null) {
      viewSelector = ".quick-add";
    }
    return $(buttonSelector).on("click", () => {
      let $quickAdd, classes, j, klass, len;
      $quickAdd = $(viewSelector);
      classes = $quickAdd.attr("class").split(/\s+/);
      for (j = 0, len = classes.length; j < len; j++) {
        klass = classes[j];
        if (klass === "visible") {
          $quickAdd.removeClass("visible");
          return;
        }
      }
      return $quickAdd.addClass("visible");
    });
  };
  bindSideModal = function (buttonSelector, modelSelector) {
    if (buttonSelector === null) {
      buttonSelector = '[data-action="sidemodal"]';
    }
    if (modelSelector === null) {
      modelSelector = "#side_modal";
    }
    return $(buttonSelector).on("click", () => $(modelSelector).modal("show"));
  };
  bindSwapper = function (selector) {
    if (selector === null) {
      selector = ".swapper, .btn-animation";
    }
    return $(selector).on("click", swapperClickEvent);
  };
  hashCode = function (str) {
    let char, hash, i, j, ref;
    hash = 0;
    for (
      i = j = 0, ref = str.length;
      ref >= 0 ? j < ref : j > ref;
      i = ref >= 0 ? ++j : --j
    ) {
      char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash &= hash;
    }
    return hash;
  };
  seededRandom = function (stringSeed) {
    let numSeed;
    numSeed = Math.abs(hashCode(stringSeed));
    return ((numSeed * 9301 + 49297) % 233280) / 233280;
  };
  getRandomColorForEmail = function (email) {
    let gravatarColors;
    gravatarColors = [
      "A33875",
      "996C86",
      "7B6C99",
      "5940AA",
      "6F82AE",
      "2553B9",
      "5099BD",
      "19909B",
      "58B588",
      "199B45",
      "ADC53C",
      "C5A513",
      "D3986D",
      "D16E13",
    ];
    return `#${
      gravatarColors[Math.floor(seededRandom(email) * gravatarColors.length)]
    }`;
  };
  gravatarFallback = function () {
    let $container, $this, bgColor, email, initial;
    $this = $(this);
    $container = $this.parent();
    if (!$container.hasClass("fallback")) {
      $container.addClass("fallback");
      email = $container.data("email");
      if (email) {
        initial = email.toString()[0].toUpperCase();
        bgColor = getRandomColorForEmail(email);
        return $container.append(
          `<div class='fallbackInitial' style='background-color: ${bgColor}'>${initial}</div>`
        );
      }
    }
  };
  gravatarSuccess = function () {
    let $container, $this;
    $this = $(this);
    $container = $this.parent();
    $container.removeClass("fallback");
    return $container.find(".fallbackInitial").remove();
  };
  bindGravatars = function ($el) {
    if ($el === null) {
      $el = $(document);
    }
    return $el.find(".avatar img").each(function () {
      this.onload = gravatarSuccess;
      this.onerror = gravatarFallback;
      return this.src;
    });
  };
  return {
    init() {
      let balanceSubnavFunc;
      balanceSubnavFunc = balanceSubnavGenerator();
      balanceSubnavFunc();
      bindAccountButtons();
      bindGravatars();
      bindQuickAdd();
      bindSideModal();
      bindStickySidebar();
      bindSwapper();
      convertSvgsToInline();
      $(window).on("scroll", _.debounce(balanceSubnavFunc, 200));
      return $(window).on("resize", _.debounce(balanceSubnavFunc, 200));
    },
    balanceSubnavGenerator,
    bindAccountButtons,
    bindGravatars,
    bindQuickAdd,
    bindSideModal,
    bindStickySidebar,
    bindSwapper,
    convertSvgsToInline,
    dropdownify,
    gravatarFallback,
    swapperClickEvent,
  };
})();

if (
  typeof module === "object" &&
  module &&
  typeof module.exports === "object"
) {
  module.exports = SendGridPalette;
} else if (typeof window === "object" && typeof window.document === "object") {
  window.SendGridPalette = SendGridPalette;
}
