import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { legacyBillingStoreConnect } from 'src/state/store';
import { MakoStateType } from 'src/state/types/makoStateType';
import * as AccountOfferingsActions from 'src/state/middle_tier/account_offerings/actions';
import { Props } from './TrialNotifications';

const getLoading = (loading: MakoStateType['loading']) => {
  return Object.values(loading).some((isLoading) => isLoading);
};

export const mapStateToProps = (state: MakoStateType) => {
  const { emailApi, marketingCampaigns } = state.accountOfferings;
  return {
    loading: getLoading(state.loading),
    emailApiPlan: emailApi?.current,
    emailApiFuturePlan: emailApi?.future,
    marketingCampaignsPlan: marketingCampaigns?.current,
    marketingCampaignsFuturePlan: marketingCampaigns?.future,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAccountOfferings: () => {
      dispatch(AccountOfferingsActions.Actions.getAccountOfferings());
    },
  };
};

const HideOnPaths = [/^\/onboarding(\/|$)/, /^\/validate_2fa(\/|$)/];

export type TrialBannerConnectorProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  Props;

export function withTrialBannerConnector(Component: React.FC<Props>) {
  const TrialBannerConnector: React.FC<TrialBannerConnectorProps> = (props) => {
    const shouldHide = HideOnPaths.some((regex) =>
      regex.test(location.pathname)
    );

    useEffect(() => {
      if (shouldHide) {
        return;
      }
      props.getAccountOfferings();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldHide]);

    return !shouldHide ? <Component {...props} /> : <></>;
  };

  return legacyBillingStoreConnect(
    mapStateToProps,
    mapDispatchToProps
  )(TrialBannerConnector);
}
