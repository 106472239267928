import { Plan } from './plan';

export enum MarketingCampaignsPlanTier {
  EndOfTrial = 'EndOfTrial',
  Free = 'Free',
  Trial = 'Trial',
  Basic = 'Basic',
  Advanced = 'Advanced',
  Unknown = 'Unknown',
}

export interface MarketingCampaignsPlan extends Plan {
  tier: MarketingCampaignsPlanTier;
  contacts: number;
  contactsOverageRate: number;
  signupForms: number;
  includesAutomation: boolean;
  hasAdsCanPublish: boolean;
  allowedTransitionTierRanks: number[];
}
