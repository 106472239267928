import { AuthedHttp } from '../../helpers/AuthedHttp';
import { ResponseErrorsDto } from '../../helperTypes/responseError';
import { CancelAccountInfoDto } from '../dtos';
import { CancelAccountRequestBody } from '../types/cancelAccount';
import { Routes } from './routes';

export async function cancelAccount(
  cancelAccountRequestBody: CancelAccountRequestBody
): Promise<CancelAccountInfoDto | ResponseErrorsDto> {
  const camAccount = window.Reqres.request('camAccount');
  if (camAccount && camAccount.new_upgrade_enabled) {
    return AuthedHttp.patch<string>(Routes.OfferingsCancelPatch, null)
      .then((response) => {
        if (response.status === 204) {
          return {};
        }
        return response.json();
      })
      .then((result) => {
        return result as CancelAccountInfoDto | ResponseErrorsDto;
      })
      .catch((err) => {
        return {
          errors: [
            {
              field: 'offerings_cancel',
              message:
                'An error occurred attempting to cancel your subscriptions.',
            },
          ],
        };
      });
  } else {
    return AuthedHttp.post<CancelAccountInfoDto>(
      Routes.Cancel,
      transformRequestBody(cancelAccountRequestBody)
    )
      .then((response) => {
        if (response.status === 204) {
          return {};
        }
        return response.json();
      })
      .then((result) => {
        return result as CancelAccountInfoDto | ResponseErrorsDto;
      })
      .catch((err) => {
        return {
          errors: [
            {
              field: 'cancel_account',
              message:
                'An error occurred attempting to cancel your subscriptions.',
            },
          ],
        };
      });
  }
}

const transformRequestBody = (
  cancelAccountRequestBody: CancelAccountRequestBody
): CancelAccountInfoDto => {
  const {
    reason,
    comment,
    isInHouse,
    isMovingOn,
    otherProvider,
  } = cancelAccountRequestBody;

  return {
    reason,
    comment,
    other_provider: otherProvider,
    // These are conditionally passed through the request body to match old flow
    ...(isMovingOn !== undefined && { moving: isMovingOn }),
    ...(isInHouse !== undefined && { in_house: isInHouse }),
  } as CancelAccountInfoDto;
};
