import { EmailApiPlan } from 'src/state/types/emailApiPlan';
import { ActionsUnion, createAction } from '../../actionBuilder';
import { ActionTypes } from './actionTypes';
import { MarketingCampaignsPlan } from 'src/state/types/marketingCampaignsPlan';
import { OfferingDto } from '../services/oes/dtos';

export interface AccountOfferings {
  catalog: OfferingDto[];
  emailApi: {
    current?: EmailApiPlan;
    future?: EmailApiPlan;
  };
  marketingCampaigns: {
    current?: MarketingCampaignsPlan;
    future?: MarketingCampaignsPlan;
  };
}

export interface UpdateOffering {
  currentOffering: string;
  currentOfferingStartDate: string;
  futureOffering?: string;
  futureStartDate?: string;
}

export const Actions = {
  // Retrieval of account offerings
  getAccountOfferings: () =>
    createAction(ActionTypes.GetAccountOfferingsRequest),
  getAccountOfferingsSuccess: (accountOfferings: AccountOfferings) =>
    createAction(ActionTypes.GetAccountOfferingsSuccess, accountOfferings),
  getAccountOfferingsFailure: () =>
    createAction(ActionTypes.GetAccountOfferingsFailure),
  // Updates to existing offerings
  updateEmailApiOffering: (updateOffering: UpdateOffering) =>
    createAction(ActionTypes.UpdateEmailApiOffering, updateOffering),
  updateMarketingCampaignsOffering: (updateOffering: UpdateOffering) =>
    createAction(ActionTypes.UpdateMarketingCampaignsOffering, updateOffering),
};

export type Actions = ActionsUnion<typeof Actions>;
export { Actions as AccountOfferingActions };
export default Actions;
