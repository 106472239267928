import moment from 'moment';
import { EmailApiPlan } from 'src/state/types/emailApiPlan';
import { MarketingCampaignsPlan } from 'src/state/types/marketingCampaignsPlan';
import {
  isEiEndOfTrial,
  isMcEndOfTrial,
  isEiOnTrial,
  isMcOnTrial,
} from 'src/helpers/offerings/trials';

export const getWhenTrialEnded = (
  currentPlan: EmailApiPlan | MarketingCampaignsPlan
): string | null => {
  const isOnEndOfTrial =
    isEiEndOfTrial(currentPlan as EmailApiPlan) ||
    isMcEndOfTrial(currentPlan as MarketingCampaignsPlan);

  if (!isOnEndOfTrial) {
    return null;
  }

  return currentPlan.startDate;
};

export const getWhenTrialEnding = (
  currentPlan: EmailApiPlan | MarketingCampaignsPlan,
  futurePlan?: EmailApiPlan | MarketingCampaignsPlan
): string | null => {
  const isOnAnyTrial =
    isEiOnTrial(currentPlan as EmailApiPlan) ||
    isMcOnTrial(currentPlan as MarketingCampaignsPlan);
  // Make sure they're on a trial currently
  if (!isOnAnyTrial) {
    return null;
  }

  // Trial isn't ending or has already ended
  if (!futurePlan) {
    return null;
  }

  const isMovingToEndOfTrial =
    isEiEndOfTrial(futurePlan as EmailApiPlan) ||
    isMcEndOfTrial(futurePlan as MarketingCampaignsPlan);
  if (!isMovingToEndOfTrial) {
    return null;
  }

  return futurePlan.startDate ?? null;
};

export const formatTrialEndDate = (endOfTrialDate: string) => {
  return moment(endOfTrialDate).format('MMMM Do, YYYY');
};
