export enum ActionTypes {
  GetAccountOfferings = 'getAccountOfferings',
  GetAccountOfferingsRequest = 'getAccountOfferings.request',
  GetAccountOfferingsSuccess = 'getAccountOfferings.success',
  GetAccountOfferingsFailure = 'getAccountOfferings.failure',
  // When the account current/future email offerings are updated
  UpdateEmailApiOffering = 'updateEmailApiOffering',
  // When the account current/future marketing campaigns offerings are updated
  UpdateMarketingCampaignsOffering = 'updateMarketingCampaignsOffering',
}
