import { Plan } from './plan';

export enum EmailApiPlanTier {
  Trial = 'Trial',
  EndOfTrial = 'End-Of-Trial',
  Free = 'Free',
  Essentials = 'Essentials',
  Pro = 'Pro',
  Premier = 'Premier',
  Unknown = 'Unknown',
}

// A higher number indicates a higher tier of service
export enum EmailApiPlanTierRank {
  Unknown = 0,
  Free = 1,
  Essentials = 2,
  Pro = 3,
  Premier = 4,
}

export interface EmailApiPlan extends Plan {
  tier: EmailApiPlanTier;
  daysOfEase: number;
  allowedTransitionTierRanks: number[];
}
