import { EmailApiPlan, EmailApiPlanTier } from 'src/state/types/emailApiPlan';
import {
  MarketingCampaignsPlan,
  MarketingCampaignsPlanTier,
} from 'src/state/types/marketingCampaignsPlan';

export const EI_TRIAL_PREFIX = 'sg.ei.trial';
export const MC_TRIAL_PREFIX = 'sg.mc.trial';

export const EI_END_OF_TRIAL_PREFIX = 'sg.ei.end-of-trial';
export const MC_END_OF_TRIAL_PREFIX = 'sg.mc.end-of-trial';

export const isEiEndOfTrial = (plan: EmailApiPlan) => {
  return plan.tier === EmailApiPlanTier.EndOfTrial;
};

export const isEiOnTrial = (plan: EmailApiPlan) => {
  return plan.tier === EmailApiPlanTier.Trial;
};

export const isMcEndOfTrial = (plan: MarketingCampaignsPlan) => {
  return plan.tier === MarketingCampaignsPlanTier.EndOfTrial;
};

export const isMcOnTrial = (plan: MarketingCampaignsPlan) => {
  return plan.tier === MarketingCampaignsPlanTier.Trial;
};
