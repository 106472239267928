import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { forkJoin, from, Observable } from 'rxjs';
import { map, mergeAll, mergeMap } from 'rxjs/operators';
import { AccountAndPlanUtils } from '../../pages/AccountAndBilling/utils';
import { CancelAccountInfoDto } from '../dtos';
import { Alert } from '../types/ui';
import { Actions as UIActions } from '../ui/actions';
import { Actions as UserPackageActions } from '../user_package/actions';
import * as CancelAccountActions from './actions';
import { ActionTypes } from './actionTypes';
import { cancelAccount } from './service';
import { Cache } from '../middle_tier/cache';
import {
  buildEmailSubscriptionOptimisticState,
  buildMarketingCampaignSubscriptionOptimisticState,
} from '../subscription_changes/buildOptimisticState';
import { MakoStateType } from '../types/makoStateType';
import { CacheKeys } from '../middle_tier/cache/keys';
import { Actions as EmailApiActions } from '../email_api_subscription/actions';
import { Actions as MarketingCampaignsActions } from '../marketing_campaigns_subscription/actions';
import { showFreeTrialsInConsole } from 'src/helpers/featureFlags/showFreeTrialsInConsole';
import { OES } from '../middle_tier/services/oes/service';
import { Slot } from '../types/slot';

// Converts async OES calls to an observable
export const getFinalOfferingAsObservable = (
  catalog_org: string,
  slot: Slot
) => {
  // Before free trials release the offerings we put the customer on
  // when they cancel are EI / MC free, after its EI / MC end of trial.
  const promise = showFreeTrialsInConsole()
    ? OES.getEndOfTrialOffering(catalog_org, slot)
    : OES.getFreeOffering(catalog_org, slot);
  return from(promise);
};

export const cancelAccountEpic = (
  action$: ActionsObservable<
    ReturnType<typeof CancelAccountActions.Actions.cancelAccountRequest>
  >,
  state$: StateObservable<MakoStateType>
): Observable<
  | ReturnType<typeof CancelAccountActions.Actions.cancelAccountFailure>
  | ReturnType<typeof CancelAccountActions.Actions.cancelAccountSuccess>
  | ReturnType<typeof EmailApiActions.getEmailApiSubscriptionSuccess>
  | ReturnType<
      typeof MarketingCampaignsActions.getMarketingCampaignsSubscriptionSuccess
    >
  | ReturnType<typeof UserPackageActions.getUserPackage>
  | ReturnType<typeof UIActions.createAlert>
> => {
  return action$.pipe(
    ofType(ActionTypes.CancelAccountRequest),
    mergeMap((action) =>
      from(cancelAccount(action.payload)).pipe(
        mergeMap((data: CancelAccountInfoDto) => {
          if (data.errors) {
            return [CancelAccountActions.Actions.cancelAccountFailure(data)];
          }

          const { catalog_org = 'sg' } = window.Reqres.request('camAccount');
          const alert = {
            type: 'success',
            text: `You've initiated a cancellation. Your subscriptions will be cancelled on ${AccountAndPlanUtils.dates.getStartOfNextMonth()}.`,
          } as Alert;

          return forkJoin([
            getFinalOfferingAsObservable(catalog_org, Slot.EI),
            getFinalOfferingAsObservable(catalog_org, Slot.MC),
          ]).pipe(
            map(([finalEiOffering, finalMcOffering]) => {
              const { userId } = state$.value.accountProfile;
              const api = { ...state$.value.emailApiSubscription };
              const mc = { ...state$.value.marketingCampaignsSubscription };

              // Subscription changes aren't reflected on the account immediately,
              // we compute the state of the account (offerings, entitlements)
              // assuming the changes go through eventually.
              const newEiSubscription = buildEmailSubscriptionOptimisticState(
                api,
                finalEiOffering.name,
                AccountAndPlanUtils.dates.getStartOfNextMonthDateUTC(),
                false
              );
              const newMcSubscription = buildMarketingCampaignSubscriptionOptimisticState(
                mc,
                finalMcOffering.name,
                AccountAndPlanUtils.dates.getStartOfNextMonthDateUTC(),
                false
              );

              Cache.setStorageData(
                userId,
                CacheKeys.EmailApiSubscription,
                newEiSubscription
              );
              Cache.setStorageData(
                userId,
                CacheKeys.MarketingCampaignSubscription,
                newMcSubscription
              );

              return [
                EmailApiActions.getEmailApiSubscriptionSuccess(
                  newEiSubscription
                ),
                MarketingCampaignsActions.getMarketingCampaignsSubscriptionSuccess(
                  newMcSubscription
                ),
                UIActions.createAlert(alert),
                CancelAccountActions.Actions.cancelAccountSuccess(),
                UserPackageActions.getUserPackage(),
              ];
            }),
            mergeAll()
          );
        })
      )
    )
  );
};

export default [cancelAccountEpic];
