/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';

export interface AppConfig {
  host: string;
  hostEnv: string;
  environment: string;
  sendgrid_com: string;
  nlvx_host: string;
  root: string;
  cookie_default_domain: string;
  recaptcha_site_key: string;
  mako_host: string;
  signup_host: string;
  labs_host: string;
  mc_host: string;
  tiara: {
    cdn_css: string;
    cdn_js: string;
  };
  packages: {
    trial40k: string;
    free100: string;
    free12k: string;
    hidden40k: string;
    hidden100k: string;
    current40k: string;
    current100k: string;
  };
  hidden_package_ids: {
    normalEnv: string;
    for_normal_signup: string[];
    s2sEnv: string;
    for_s2s_signup: string[];
  };
  mako_auth_token: string;
  mako_message: string;
  zuora_url: string;
  zuora_payment_page_id: string;
  sift_science_key: string;

  thirdPartyScripts: boolean;
  showEmailValidationKeys: boolean;
  showAntiSpoof: boolean;
  showScopeSelectorBeta: boolean;
  showAzureResellerUsage: boolean;
  for_normal_signup: string[];
  for_s2s_signup: string[];
  authToken: string;
  domainConnectEnabled: boolean;
  enableDmarc: boolean;
}

interface ApiConfigStore {
  loaded: boolean;
  config: AppConfig;
}

const appConfigState: ApiConfigStore = {
  loaded: false,
  config: {} as AppConfig,
};

export const configureApplication = createAction<AppConfig>(
  'CONFIGURE_APPLICATION'
);

export const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState: appConfigState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(configureApplication, (state, action) => {
      state.loaded = true;
      state.config = action.payload;
    });
  },
});
