import React, { useMemo } from 'react';
import { Alert } from '@twilio-paste/alert';
import { Text } from '@twilio-paste/core/text';
import { content } from '../content';
import { Anchor } from '@twilio-paste/core';

export interface Props {
  trialEndDate: string;
}

export const TrialEnding: React.VFC<Props> = ({ trialEndDate }) => {
  const { infoSentence, actionSentence, finalSentence } = useMemo(
    () => content.trialEnding(trialEndDate),
    [trialEndDate]
  );

  return (
    <Alert variant="neutral">
      <Text as="p">
        {infoSentence}{' '}
        <Anchor href={content.upgradeLink.href}>
          {content.upgradeLink.text}
        </Anchor>{' '}
        {actionSentence}{' '}
        <Anchor href={content.guideLink.href}>{content.guideLink.text}</Anchor>{' '}
        {finalSentence}
      </Text>
    </Alert>
  );
};
