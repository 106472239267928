import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { Actions as EmailApiActions } from '../email_api_subscription/actions';
import { Actions as MarketingCampaignsActions } from '../marketing_campaigns_subscription/actions';
import { Actions as UpgradeAction } from './actions';
import { catchError, concatMap } from 'rxjs/operators';
import { ActionTypes } from './actionTypes';
import { upgradeService, startTrial40k } from './service';
import { MakoStateType } from '../types/makoStateType';
import { PlanType } from '../types/planType';
import { Cache } from '../middle_tier/cache';
import { CacheKeys } from '../middle_tier/cache/keys';
import {
  buildEmailSubscriptionOptimisticState,
  buildMarketingCampaignSubscriptionOptimisticState,
} from './buildOptimisticState';
import { Actions as UIActions } from '../ui/actions';
import { Alert } from '../types/ui';
import { AccountAndPlanUtils } from '../../pages/AccountAndBilling/utils';
import { createUpgradeBannerText } from '../utils/upgradeBanner';

const updateToTrial40k = (
  action$: ActionsObservable<ReturnType<typeof UpgradeAction.startTrial40k>>
): Observable<
  | ReturnType<typeof UpgradeAction.changeSubscription>
  | ReturnType<typeof UpgradeAction.startTrial40kSuccess>
  | ReturnType<typeof UpgradeAction.startTrial40kFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.StartTrial40KRequest),
    concatMap((action) => {
      return from(startTrial40k(action.payload)).pipe(
        concatMap((data) => {
          return [
            UpgradeAction.startTrial40kSuccess(),
            UpgradeAction.changeSubscription(data),
          ];
        }),
        catchError(() => {
          return [UpgradeAction.startTrial40kFailure()];
        })
      );
    })
  );
};

export const updateSubscription = (
  action$: ActionsObservable<
    ReturnType<typeof UpgradeAction.changeSubscription>
  >,
  state$: StateObservable<MakoStateType>
): Observable<
  | ReturnType<typeof EmailApiActions.getEmailApiSubscriptionSuccess>
  | ReturnType<
      typeof MarketingCampaignsActions.getMarketingCampaignsSubscriptionSuccess
    >
  | ReturnType<typeof UpgradeAction.changeSubscriptionSuccess>
  | ReturnType<typeof UpgradeAction.changeSubscriptionFailure>
  | ReturnType<typeof UIActions.createAlert>
> => {
  return action$.pipe(
    ofType(ActionTypes.SubscriptionChangesRequest),
    concatMap((action) => {
      // response handling from plan change call.
      return from(upgradeService(action.payload)).pipe(
        concatMap(() => {
          const { planType, planId, isUpgrade, planName } = action.payload;
          const { userId } = state$.value.accountProfile;
          let api = Object.assign({}, state$.value.emailApiSubscription);
          let mc = Object.assign(
            {},
            state$.value.marketingCampaignsSubscription
          );
          if (planType === PlanType.EmailApi) {
            api = buildEmailSubscriptionOptimisticState(
              api,
              planId,
              // Temporary: remove when SSAPI starts returning dates for offering changes
              isUpgrade
                ? AccountAndPlanUtils.dates.getCurrentDateUTC()
                : AccountAndPlanUtils.dates.getStartOfNextMonthDateUTC(),
              isUpgrade
            );
            Cache.setStorageData(userId, CacheKeys.EmailApiSubscription, api);
          } else {
            mc = buildMarketingCampaignSubscriptionOptimisticState(
              mc,
              planId,
              // Temporary: remove when SSAPI starts returning dates for offering changes
              isUpgrade
                ? AccountAndPlanUtils.dates.getCurrentDateUTC()
                : AccountAndPlanUtils.dates.getStartOfNextMonthDateUTC(),
              isUpgrade
            );
            Cache.setStorageData(
              userId,
              CacheKeys.MarketingCampaignSubscription,
              mc
            );
          }
          let text;
          if (isUpgrade) {
            text = createUpgradeBannerText(planType, planName);
          } else {
            text = `You've initiated a downgrade. The downgrade will take effect on 
            ${AccountAndPlanUtils.dates.getStartOfNextMonth()} and will be
            reflected on your next bill.`;
          }

          const alert = {
            type: 'success',
            text,
          } as Alert;
          return [
            UIActions.createAlert(alert),
            EmailApiActions.getEmailApiSubscriptionSuccess(api),
            MarketingCampaignsActions.getMarketingCampaignsSubscriptionSuccess(
              mc
            ),
            UpgradeAction.changeSubscriptionSuccess(),
          ];
        }),
        catchError(() => {
          return of(UpgradeAction.changeSubscriptionFailure());
        })
      );
    })
  );
};

export default [updateSubscription, updateToTrial40k];
