const Marionette = require("backbone.marionette");
const { analytics } = require("@sendgrid/bloodhound");
const qs = require("qs");

// Route helpers
const RouterHelpers = require("./router_helpers");
const IPsCollection = require("./collections/ips_collection");

const { hasUtm, getUtmParams } = require("./helpers/utmHelpers");
const AntiSpoof = require("./helpers/AntiSpoof.ts");
const {
  determineShouldShowTwilioSMSPromoCard,
} = require("./helpers/TwilioSMSPromoCard.ts");
const { showNewSetupGuide } = require("./helpers/onboarding");
const {
  shouldShowIPAddressesBeta,
} = require("./helpers/shouldShowIPAddressesBeta");
const { shouldShowLogin } = require("./helpers/shouldShowLogin");
const { shouldShowLogout } = require("./helpers/shouldShowLogout");
const { shouldShowLoginBeta } = require("./helpers/shouldShowLoginBeta");
const { shouldShowLoginAuth0 } = require("./helpers/shouldShowLoginAuth0");
const { EmailApiPlanTierRank } = require("./state/types/emailApiPlan");
const {
  shouldShowFreeTrialOnboarding,
} = require("./helpers/shouldShowFreeTrialOnboarding");
const { shouldShowEmailLogs } = require("./helpers/emailLogs");

const MakoRouter = Marionette.AppRouter.extend({
  routes: {
    "": "dashboard",
    "signup/update_email": "signupEmailValidation",
    "signup/validate": "signupValidate",
    signup: "redirectToSignupApp",
    labs: "redirectToLabsApp",
    login: "login",
    "login/oauth": "login",
    // login route below is being used for SSO - notify reseller team before removing
    "login/from-site?token=:token": "tokenLogin",
    "oauth/consent": "oAuthConsent",
    "oauth/error": "oAuthError",
    "oauth/unauthorized": "oAuthUnauthorized",
    "auth0/callback": "auth0",
    forgot_password: "forgotPassword",
    reset_password: "resetPassword",
    password_reset_required: "passwordResetRequired",
    "2fa_email_checkpoint": "twoFactorAuthEmailCheckpoint",
    token_exchange: "tokenExchange",
    reset_credentials: "resetCredentials",
    validate_2fa: "validate2FA",
    "token_exchange/verify": "tokenExchange",
    guide: "guide",
    "guide/complete": "guideParty",
    "guide/integrate": "guideIntegrate",
    "guide/integrate/langs": "guideLangs",
    "guide/integrate/langs/:lang": "guideLang",
    "guide/integrate/langs/:lang/verify": "guideVerify",
    templates: "templateEngine",
    marketing_emails: "marketingEmails",
    email_activity: "emailActivity",
    "email_activity/download": "emailActivityCsvDownload",
    "email_activity/:msg_id": "emailActivityItem",

    email_validation: "emailValidation",
    "email_validation/download": "emailValidationCsvDownload",

    email_logs: "emailLogs",

    expert_insights: "expertInsightsLandingPage",
    "expert_insights/download": "expertInsightsPdfDownload",
    "expert_insights/purchase": "expertInsightsPurchasePage",

    "email_insights/deliverability": "emailInsightsDeliverability",
    "email_insights/deliverability/overview": "emailInsightsDeliverability",
    "email_insights/deliverability/mailbox_providers":
      "emailInsightsDeliverability",
    "email_insights/deliverability/bounced_and_blocked":
      "emailInsightsDeliverability",
    "email_insights/deliverability/spam_and_unsubscribes":
      "emailInsightsDeliverability",

    "account/details": "accountDetails",
    "account/billing": "accountDetails",
    "account/products": "accountDetails",
    "account/products/addons": "accountDetails",
    "settings/billing/premier": "billing",
    "account/billing/choose_plan": "accountDetails",
    "account/billing/choose_plan_marketing_campaigns": "accountDetails",
    "account/billing/expert_services/implementation": "accountDetails",
    "account/billing/expert_services/ongoing": "accountDetails",
    payment: "oneTimePayment",
    "settings/account": "redirectToAccountPage",
    "settings/alerts": "settingsAlerts",
    "settings/auth": "settingsAuth",
    "settings/subusers": "subuserManagement",
    "settings/subusers/:username/settings": "subuserManagementSettings",
    "settings/subusers/:username/profile": "subuserManagementProfile",
    "settings/api_keys": "apiKeys",
    "settings/access": "accessSettings",
    "settings/access/legacy": "accessSettingsLegacy",

    // We deprecated these legacy routes and related views
    // We redirect users to equivalent Sender Authentication pages who
    // still have the old routes cached in the browser
    "settings/whitelabel": "senderAuthOverview",
    "settings/whitelabel/domains": "senderAuthDomains",
    "settings/whitelabel/domains/:id": "senderAuthDomainDetails",
    "settings/whitelabel/links": "senderAuthEmailLinks",
    "settings/whitelabel/links/:id": "senderAuthEmailLinkDetails",
    "settings/whitelabel/ips": "senderAuthIPs",
    "settings/whitelabel/ips/:id": "senderAuthIPDetails",

    "settings/sender_auth": "senderAuthLandingPage",

    "settings/sender_auth/senders": "senderAuthSenders",
    "settings/sender_auth/senders/new": "senderAuthSenders",
    "settings/sender_auth/senders/info": "senderAuthSenders",
    "settings/sender_auth/senders/:id/edit": "senderAuthSenders",
    "settings/sender_auth/senders/verify": "senderAuthSendersVerify",

    "settings/sender_auth/domains": "senderAuthDomainList",
    "settings/sender_auth/domain/get/:id": "senderAuthDomainDetail",
    "settings/sender_auth/domain/create": "senderAuthCreateDomain",
    "settings/sender_auth/domain/configure": "senderAuthCreateDomainStep2",

    "settings/sender_auth/verify": "senderAuthVerify",

    "settings/sender_auth/links": "senderAuthLinkList",
    "settings/sender_auth/link/get/:id": "senderAuthLinkDetail",
    "settings/sender_auth/link/create": "senderAuthCreateLink",
    "settings/sender_auth/link/configure": "senderAuthCreateLinkStep2",
    "public/sender_auth/verification": "senderAuthPublicVerification",

    "settings/sender_auth/reverse_dns": "senderAuthReverseDNSList",
    "settings/sender_auth/reverse_dns/create": "senderAuthReverseDNSCreate",
    "settings/sender_auth/reverse_dns/get/:id": "senderAuthReverseDNSDetails",

    "settings/sender_auth/confirmation/instructions_sent":
      "senderAuthInstructionsSent",
    "settings/sender_auth/confirmation/installation_confirmed":
      "senderAuthInstallationConfirmed",
    "settings/sender_auth/confirmation/link_expired": "senderAuthLinkExpired",
    "settings/sender_auth/confirmation/success": "senderAuthSuccess",
    "settings/sender_auth/confirmation/sorry": "senderAuthSorry",

    "settings/parse": "parseSettings",
    "settings/mail_settings": "mailSettings",
    "settings/mail_settings/webhook_settings": "webhookSettings",
    "settings/partners": "redirectToMailSettings",
    "settings/tracking": "mailSettingsTracking",
    "settings/billing": "redirectToBillingPage",
    "settings/billing/addons/email_activity": "accountDetails",
    "settings/billing/addons/email_activity/success": "accountDetails",
    settings: "redirectSettings",
    "settings/teammates": "teammates",
    "settings/ip_addresses": "ipAddresses",
    "legacy/settings/ip_addresses": "legacyIpAddresses",
    "settings/ip_addresses/pools": "ipAddresses",
    "legacy/settings/ip_addresses/pools": "legacyIpAddresses",
    "settings/ip_addresses/purchase_success": "ipAddressesPurchaseSuccess",

    "settings/sso": "ssoSettings",
    "settings/sso/configure_idp/:id": "ssoSettingsConfigureIDP",
    "settings/sso/configure_sp/:id": "ssoSettingsConfigureSP",
    "settings/sso/edit/:id": "ssoSettingsEdit",

    "subuser/access": "subuserAccess",

    "suppressions/blocks": "suppressionsBlocks",
    "suppressions/bounces": "suppressionsBounces",
    "suppressions/invalid_emails": "suppressionsInvalidEmails",
    "suppressions/spam_reports": "suppressionsSpamReports",
    "suppressions/global_unsubscribes": "suppressionsGlobalUnsubscribes",

    "twilio/sms": "twilioSMSPromo",

    onboarding: "onboarding",

    "teammates/confirm": "teammatesConfirmation",
    security_checkup: "securityCheckup",
    "errors/expired_reset_password": "errorsExpiredResetPassword",
    logout: "logout",
    "reseller/sso/error": "resellerSsoError",
    "*notFound": "notFound404",
  },

  onRoute() {
    RouterHelpers.onRoute();
  },

  senderAuthInstructionsSent() {
    import(
      "routes/sender_authentication/confirmation/instructions_sent.tsx"
    ).then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        requiredScopes: "whitelabel.update",
        accessDeniedOptions: {
          restrictAccessRequest: true,
        },
      });
    });
  },
  senderAuthInstallationConfirmed() {
    import(
      "routes/sender_authentication/confirmation/public_install_confirmed.tsx"
    ).then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },
  senderAuthLinkExpired() {
    import(
      "routes/sender_authentication/confirmation/public_link_expired.tsx"
    ).then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },
  senderAuthSuccess() {
    import("routes/sender_authentication/confirmation/successful.tsx").then(
      (view) => {
        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
        });
      }
    );
  },
  senderAuthSorry() {
    import("routes/sender_authentication/confirmation/sorry.tsx").then(
      (view) => {
        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
        });
      }
    );
  },

  senderAuthLandingPage() {
    import("./routes/sender_authentication/landing_page.tsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        requiredScopes: "whitelabel.read",
        accessDeniedOptions: { scopeGroupName: "Whitelabel" },
      });
    });
  },

  senderAuthSenders(id) {
    import("./routes/sender_authentication/senders.tsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        requiredScopes: "whitelabel.read",
        accessDeniedOptions: {
          scopeGroupName: "Whitelabel",
          canTeammateRequestAccess: true,
        },
        args: { initialEditRouteSenderId: id ? parseInt(id, 10) : null },
      });
    });
  },

  senderAuthSendersVerify(queryParams) {
    import("./routes/sender_authentication/senders_verify.tsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        requiredScopes: "whitelabel.read",
        accessDeniedOptions: {
          scopeGroupName: "Whitelabel",
          canTeammateRequestAccess: true,
        },
        queryParams,
      });
    });
  },

  senderAuthDomainList() {
    import("./routes/sender_authentication/domain_list.tsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        requiredScopes: "whitelabel.read",
        accessDeniedOptions: { scopeGroupName: "Whitelabel" },
      });
    });
  },

  senderAuthLinkList() {
    import("./routes/sender_authentication/link_list.tsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        requiredScopes: "whitelabel.read",
        accessDeniedOptions: { scopeGroupName: "Whitelabel" },
      });
    });
  },

  senderAuthDomainDetail(id) {
    import("./routes/sender_authentication/domain_detail").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        id,
        requiredScopes: "whitelabel.read",
        accessDeniedOptions: { scopeGroupName: "Whitelabel" },
        sendgridAppStore: this.getOption("sendgridAppStore"),
      });
    });
  },

  senderAuthLinkDetail(id) {
    import("./routes/sender_authentication/link_detail").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        id,
        requiredScopes: "whitelabel.read",
        accessDeniedOptions: { scopeGroupName: "Whitelabel" },
      });
    });
  },
  senderAuthCreateDomain() {
    import("./routes/sender_authentication/create_domain.tsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        requiredScopes: "whitelabel.update",
        accessDeniedOptions: { scopeGroupName: "Whitelabel" },
      });
    });
  },
  senderAuthCreateDomainStep2() {
    import("./routes/sender_authentication/create_domain_step2.tsx").then(
      (view) => {
        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
          requiredScopes: "whitelabel.update",
          accessDeniedOptions: { scopeGroupName: "Whitelabel" },
        });
      }
    );
  },
  senderAuthVerify() {
    import("./routes/sender_authentication/verify.tsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        requiredScopes: "whitelabel.update",
        accessDeniedOptions: { scopeGroupName: "Whitelabel" },
        sendgridAppStore: this.getOption("sendgridAppStore"),
      });
    });
  },
  senderAuthCreateLink() {
    import("./routes/sender_authentication/create_link.tsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        requiredScopes: "whitelabel.update",
        accessDeniedOptions: { scopeGroupName: "Whitelabel" },
      });
    });
  },
  senderAuthCreateLinkStep2() {
    import("./routes/sender_authentication/create_link_step2.tsx").then(
      (view) => {
        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
          requiredScopes: "whitelabel.update",
          accessDeniedOptions: { scopeGroupName: "Whitelabel" },
        });
      }
    );
  },

  senderAuthReverseDNSList() {
    const isSubuser =
      window.Reqres.request("currentUser").userType === "subuser";

    const hasIpsCollection = new IPsCollection();
    hasIpsCollection.limit = 1;
    hasIpsCollection.exclude_whitelabels = false;

    // This is the preffered method to find IP's but for some reason resellers doesn't allow access to the packages endpoint
    // the previous hasIPsCollection method is from the previous iteration of whitelabel/sender auth

    // const isAtLeastProUser = window.Reqres.request("userPackage").attributes
    // .has_ip;

    hasIpsCollection.fetch().done((ele) => {
      const isAtLeastProUser = Boolean(ele.length);
      if (isSubuser) {
        // subusers will see "You don't have access to this page..."
        this.errorRouter.errorsAccessDenied({ restrictAccessRequest: true });
        return;
      }

      if (isAtLeastProUser) {
        import("./routes/sender_authentication/rdns_list").then((view) => {
          view.default({
            errorRouter: this.errorRouter,
            layoutController: this.getOption("layoutController"),
            requiredScopes: "whitelabel.read",
            accessDeniedOptions: { scopeGroupName: "Whitelabel" },
          });
        });
        return;
      }

      // free, essentials, and other legacy packages below pro will see "This feature is not available on your current plan..."
      this.errorRouter.errorsAccessDenied({ restrictAccessRequest: false });
    });
  },

  senderAuthReverseDNSCreate() {
    const isSubuser =
      window.Reqres.request("currentUser").userType === "subuser";

    const hasIpsCollection = new IPsCollection();
    hasIpsCollection.limit = 1;
    hasIpsCollection.exclude_whitelabels = false;

    hasIpsCollection.fetch().done((ele) => {
      const isAtLeastProUser = Boolean(ele.length);

      if (isSubuser) {
        // subusers will see "You don't have access to this page..."
        this.errorRouter.errorsAccessDenied({ restrictAccessRequest: true });
        return;
      }

      if (isAtLeastProUser) {
        import("./routes/sender_authentication/create_rdns.tsx").then(
          (view) => {
            view.default({
              errorRouter: this.errorRouter,
              layoutController: this.getOption("layoutController"),
              requiredScopes: "whitelabel.update",
              accessDeniedOptions: { scopeGroupName: "Whitelabel" },
            });
          }
        );
        return;
      }

      // free, essentials, and other legacy packages below pro will see "This feature is not available on your current plan..."
      this.errorRouter.errorsAccessDenied({ restrictAccessRequest: false });
    });
  },

  senderAuthReverseDNSDetails(id) {
    const isSubuser =
      window.Reqres.request("currentUser").userType === "subuser";

    const hasIpsCollection = new IPsCollection();
    hasIpsCollection.limit = 1;
    hasIpsCollection.exclude_whitelabels = false;

    hasIpsCollection.fetch().done((ele) => {
      const isAtLeastProUser = Boolean(ele.length);

      if (isSubuser) {
        // subusers will see "You don't have access to this page..."
        this.errorRouter.errorsAccessDenied({ restrictAccessRequest: true });
        return;
      }

      if (isAtLeastProUser) {
        import("./routes/sender_authentication/rdns_detail").then((view) => {
          view.default({
            errorRouter: this.errorRouter,
            layoutController: this.getOption("layoutController"),
            id,
            requiredScopes: "whitelabel.read",
            accessDeniedOptions: { scopeGroupName: "Whitelabel" },
          });
        });
        return;
      }

      // free, essentials, and other legacy packages below pro will see "This feature is not available on your current plan..."
      this.errorRouter.errorsAccessDenied({ restrictAccessRequest: false });
    });
  },

  senderAuthPublicVerification() {
    import(
      "./routes/sender_authentication/public_domain_verification.tsx"
    ).then((view) => {
      view.default({
        isPublicRoute: true,
        region: this.getOption("region"),
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  initialize() {
    this.errorRouter = this.getOption("errorRouter");
    this.authController = this.getOption("authController");
  },

  before(route) {
    RouterHelpers.before(this, route);
  },

  signupEmailValidation() {
    import("routes/signup_email_validation").then((view) => {
      view.default({
        region: this.getOption("region"),
      });
    });
  },

  signupValidate(queryParams) {
    import("./routes/signup_validate.tsx").then((view) => {
      view.default({
        isPublicRoute: true,
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        queryParams,
      });
    });
  },

  redirectToLoginApp() {
    window.EventBus.trigger("redirectToLoginApp");
  },

  redirectToLoginAppPageRedirect() {
    window.EventBus.trigger("redirectToLoginAppPageRedirect");
  },

  redirectToLoginAppLogoutPage() {
    window.EventBus.trigger("redirectToLoginAppLogoutPage");
  },

  redirectToSignupApp() {
    window.EventBus.trigger("redirectToSignupApp");
  },

  redirectToLabsApp() {
    window.EventBus.trigger("redirectToLabsApp");
  },

  redirectToAccountPage() {
    window.EventBus.trigger("redirectToAccountPage");
  },

  redirectToBillingPage() {
    window.EventBus.trigger("redirectToBillingPage");
  },

  login(queryParams) {
    const shouldShowLoginPage = shouldShowLogin();

    const hasLoginAuth0Params = qs.parse(queryParams).auth0;
    // login rewrite auth0 is gated in production
    const shouldShowLoginAuth0Page = shouldShowLoginAuth0();

    const hasLoginBetaPageParams = qs.parse(queryParams).beta;
    // login rewrite beta page is gated in production
    const shouldShowLoginBetaPage = shouldShowLoginBeta();

    if (hasLoginAuth0Params && shouldShowLoginAuth0Page) {
      import("routes/login_auth0").then((view) => {
        view.default({
          authController: this.getOption("authController"),
          region: this.getOption("region"),
          queryParams,
        });
      });
      return;
    }

    if (hasLoginBetaPageParams && shouldShowLoginBetaPage) {
      import("routes/login_beta").then((view) => {
        view.default({
          errorRouter: this.errorRouter,
          isPublicRoute: true,
          basePath: this.getOption("basePath"),
          history: this.getOption("history"),
          region: this.getOption("region"),
        });
      });
      return;
    }

    if (shouldShowLoginPage) {
      import("routes/login").then((view) => {
        view.default({
          authController: this.getOption("authController"),
          region: this.getOption("region"),
          queryParams,
        });
      });
      return;
    }
    this.redirectToLoginApp();
  },

  tokenLogin(token) {
    this.getOption("authController").loginWithToken(token);
  },

  oAuthConsent() {
    import("routes/oauth/consent.tsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        store: this.getOption("store"),
      });
    });
  },

  oAuthUnauthorized(queryParams) {
    import("routes/oauth/unauthorized.tsx").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        queryParams,
      });
    });
  },

  oAuthError(error_page, queryParams) {
    import("routes/oauth/error.tsx").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        queryParams,
      });
    });
  },

  forgotPassword() {
    import("routes/forgot_password").then((view) => {
      view.default({
        region: this.getOption("region"),
      });
    });
  },

  // Email password reset flow
  resetPassword(queryParams) {
    import("routes/reset_password").then((view) => {
      view.default({
        region: this.getOption("region"),
        queryParams,
      });
    });
  },

  // Strong password reset flow
  passwordResetRequired() {
    import("routes/password_reset_required").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  validate2FA(queryParams) {
    import("routes/validate_2fa/validate_2fa").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        errorRouter: this.errorRouter,
        sendgridAppStore: this.getOption("sendgridAppStore"),
        queryParams,
      });
    });
  },

  dashboard() {
    const userId = window.Reqres.request("currentUser").user_id;
    AntiSpoof.determineShouldShowAntiSpoofFriction(userId).then(
      (shouldShowAntiSpoofFriction) => {
        // anti-spoof trumps all
        if (shouldShowAntiSpoofFriction) {
          window.EventBus.trigger("navigate", "/guide");
          return;
        }

        determineShouldShowTwilioSMSPromoCard().then(
          (shouldShowTwilioSMSPromoCard) => {
            import("routes/dashboard").then((view) => {
              view.default({
                emailStatsCollection: this.getOption("emailStatsCollection"),
                userProfileModel: this.getOption("userProfileModel"),
                layoutController: this.getOption("layoutController"),
                emailModel: this.getOption("emailModel"),
                usernameModel: this.getOption("usernameModel"),
                shouldShowTwilioSMSPromoCard,
                store: this.getOption("store"),
                sendgridAppStore: this.getOption("sendgridAppStore"),
              });
            });
          }
        );
      }
    );
  },

  guide() {
    // is feature toggle true and do new fields exist
    if (showNewSetupGuide()) {
      import("routes/guide/setup_guide.tsx").then((view) => {
        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
          sendgridAppStore: this.getOption("sendgridAppStore"),
        });
      });
    } else {
      import("routes/guide/legacy_setup_guide.tsx").then((view) => {
        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
          store: this.getOption("store"),
        });
      });
    }
  },

  guideParty() {
    import("routes/guide_party").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  guideIntegrate() {
    if (AntiSpoof.isInAntiSpoofLocalStorage()) {
      window.EventBus.trigger("navigate", "/guide");
      return;
    }

    import("routes/guide_integrate").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  guideLangs() {
    if (AntiSpoof.isInAntiSpoofLocalStorage()) {
      window.EventBus.trigger("navigate", "/guide");
      return;
    }

    import("routes/guide_langs").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  guideLang(language) {
    if (AntiSpoof.isInAntiSpoofLocalStorage()) {
      window.EventBus.trigger("navigate", "/guide");
      return;
    }

    import("routes/guide_lang").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        language,
      });
    });
  },

  guideVerify(language) {
    if (AntiSpoof.isInAntiSpoofLocalStorage()) {
      window.EventBus.trigger("navigate", "/guide");
      return;
    }

    import("routes/guide_verify").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        language,
      });
    });
  },

  emailActivity(queryParams) {
    import("routes/email_activity").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        queryParams,
      });
    });
  },

  emailActivityItem(msgId, queryParams) {
    import("routes/email_activity").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        msgId,
        queryParams,
      });
    });
  },

  emailActivityCsvDownload(queryParams) {
    import("routes/email_activity_csv_download").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        queryParams,
      });
    });
  },

  emailValidation() {
    // Read the account's EI Tier Rank, falling back to 0 (Unknown) if the authenticated user does not have the user.account.read scope.
    const eiTierRank =
      window.Reqres.request("getEntitlement", "ei_tier_rank") ||
      EmailApiPlanTierRank.Unknown;

    // Determine tier
    const isProOrHigher = eiTierRank >= EmailApiPlanTierRank.Pro;
    const isFreeEssentialsUser =
      EmailApiPlanTierRank.Unknown < eiTierRank &&
      eiTierRank < EmailApiPlanTierRank.Pro;

    // Check if the user has the ev.read scope
    const hasEVReadScope = window.Reqres.request(
      "hasScopes",
      "validations.email.read"
    );

    // We'll only bypass the validation scope check and show the feature unavailable upsell card
    // for when we encounter for a free/essentials user (parent or teammate with user.account.read and without ev.read scope)
    const isSubuser =
      window.Reqres.request("currentUser").userType === "subuser";
    const isSubuserWithoutEVReadScope = isSubuser && !hasEVReadScope;
    /*
      Teammate Flows:
        - with ev.read scope
          => reporting page (and potentially see empty no recent data card)
        - without ev.read scope
          - with user.account.read scope
            - essentials/free
              => feature unavailable upsell (bypass scope check)
            - pro/premier
              => teammate request access card
          - without user.account.read scope
            => teammate request access card

      Subuser Flows:
        - with ev.read scope
          => reporting page (and potentially see empty no recent data card)
        - without ev.read scope
          => feature unavailable upsell card

      Parent Flows:
        - with ev.read scope (pro/premier)
          => reporting page (and potentially see empty no recent data card)
        - without ev.read scope (free/essentials)
          => feature unavailable upsell card (bypass scope check)
    */
    import("routes/email_validation/email_validation_landing_page.tsx").then(
      (view) => {
        view.default({
          layoutController: this.getOption("layoutController"),
          errorRouter: this.errorRouter,
          requiredScopes: ["validations.email.read", "user.account.read"], // user.account.read is needed to get OES entitlements for accurate plan determination
          accessDeniedOptions: {
            scopeGroupName: "Email Validation",
            canTeammateRequestAccess: true,
          },
          args: { isProUserAndUp: isProOrHigher },
          // If we bypass the scope check for a subuser without ev read scope and a free/essentials user,
          // we will no longer go through the teammate request access scope checks from the accessDeniedOptions
          // and show the feature unavailable upsell card
          bypassScopeCheck: isSubuserWithoutEVReadScope || isFreeEssentialsUser,
        });
      }
    );
  },

  accountDetails() {
    const currentPath = window.location.pathname;
    const utmInPath = hasUtm();

    // We want people from ad campaigns hitting our new expert_insights landing page and we can't
    // render it on this url so lets redirect to the landing page when we see they have one of
    // the utm parameters.
    if (
      currentPath.includes("/account/billing/expert_services/ongoing") &&
      utmInPath
    ) {
      window.EventBus.trigger("navigate", "/expert_insights");
      return;
    }
    /* eslint-enable camelcase */

    import("routes/account_details").then((view) => {
      return view.default({
        // needed for redux
        store: this.getOption("store"),
        sendgridAppStore: this.getOption("sendgridAppStore"),
        // needed for react router
        basePath: this.getOption("basePath"),
        history: this.getOption("history"),
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  settingsAuth() {
    import("routes/auth/settings_auth.tsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        sendgridAppStore: this.getOption("sendgridAppStore"),
        history: this.getOption("history"),
        layoutController: this.getOption("layoutController"),
        basePath: this.getOption("basePath"),
      });
    });
  },

  settingsAlerts() {
    import("routes/alerts/settings_alerts.tsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  templateEngine() {
    import("routes/template_engine").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  marketingEmails() {
    import("routes/marketing_emails").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  // We deprecated these legacy routes and related views
  // We redirect users to equivalent Sender Authentication pages who
  // still have the old routes cached in the browser
  senderAuthOverview() {
    window.EventBus.trigger("navigate", "/settings/sender_auth");
  },
  senderAuthDomains() {
    window.EventBus.trigger("navigate", "/settings/sender_auth/domains");
  },
  senderAuthDomainDetails(domainId = "") {
    window.EventBus.trigger(
      "navigate",
      `/settings/sender_auth/domain/get/${domainId}`
    );
  },
  senderAuthEmailLinks() {
    window.EventBus.trigger("navigate", "/settings/sender_auth/links");
  },
  senderAuthEmailLinkDetails(linkId = "") {
    window.EventBus.trigger(
      "navigate",
      `/settings/sender_auth/link/get/${linkId}`
    );
  },
  senderAuthIPs() {
    window.EventBus.trigger("navigate", "/settings/sender_auth/reverse_dns");
  },
  senderAuthIPDetails(ipId = "") {
    window.EventBus.trigger(
      "navigate",
      `/settings/sender_auth/reverse_dns/get/${ipId}`
    );
  },

  redirectSettings() {
    window.EventBus.trigger("navigate", "settings/account");
  },

  subuserManagement(queryParams) {
    import("routes/subuser_management").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        emailModel: this.getOption("emailModel"),
        layoutController: this.getOption("layoutController"),
        authController: this.authController,
        queryParams,
      });
    });
  },

  subuserManagementSettings(username) {
    import("routes/subuser_management_settings").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        username,
      });
    });
  },

  subuserManagementProfile(username) {
    import("routes/subuser_management_profile").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        username,
      });
    });
  },

  parseSettings() {
    import("routes/settings_parse").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  redirectToMailSettings() {
    window.EventBus.trigger("navigate", "settings/mail_settings");
  },

  mailSettings() {
    import("routes/mail_settings/settings_mail_settings.tsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        requiredScopes: "mail_settings.read",
        accessDeniedOptions: {
          scopeGroupName: "Mail Settings",
          bypassScopeCheck: true,
        },
        store: this.getOption("store"),
        basePath: this.getOption("basePath"),
      });
    });
  },

  webhookSettings() {
    import("routes/mail_settings/webhook_settings.tsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        sendgridAppStore: this.getOption("sendgridAppStore"),
      });
    });
  },

  mailSettingsTracking() {
    import("routes/tracking/settings_tracking.tsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        requiredScopes: "tracking_settings.read",
        accessDeniedOptions: {
          scopeGroupName: "Tracking",
          bypassScopeCheck: true,
        },
        store: this.getOption("store"),
        basePath: this.getOption("basePath"),
      });
    });
  },

  apiKeys() {
    import("routes/settings_api_keys").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  billing() {
    import("routes/billing").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  accessSettings() {
    import(
      "routes/ip_access_management/settings_ip_access_management.tsx"
    ).then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        sendgridAppStore: this.getOption("sendgridAppStore"),
        requiredScopes: [
          "access_settings.whitelist.read",
          "access_settings.activity.read",
        ],
        accessDeniedOptions: {
          scopeGroupName: "Security",
          title: "IP Access Management",
          canTeammateRequestAccess: true,
        },
      });
    });
  },

  accessSettingsLegacy() {
    import("routes/settings_access_legacy").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  suppressionsBlocks() {
    import("routes/suppressions/suppressions_blocks.tsx").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        errorRouter: this.errorRouter,
        requiredScopes: "suppression.read",
        accessDeniedOptions: {
          scopeGroupName: "Suppressions",
          canTeammateRequestAccess: true,
        },
      });
    });
  },

  suppressionsBounces() {
    import("routes/suppressions/suppressions_bounces.tsx").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        errorRouter: this.errorRouter,
        requiredScopes: "suppression.read",
        accessDeniedOptions: {
          scopeGroupName: "Suppressions",
          canTeammateRequestAccess: true,
        },
      });
    });
  },

  suppressionsInvalidEmails() {
    import("routes/suppressions/suppressions_invalid_emails.tsx").then(
      (view) => {
        view.default({
          layoutController: this.getOption("layoutController"),
          errorRouter: this.errorRouter,
          requiredScopes: "suppression.read",
          accessDeniedOptions: {
            scopeGroupName: "Suppressions",
            canTeammateRequestAccess: true,
          },
        });
      }
    );
  },

  suppressionsSpamReports() {
    import("routes/suppressions/suppressions_spam_reports.tsx").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        errorRouter: this.errorRouter,
        requiredScopes: "suppression.read",
        accessDeniedOptions: {
          scopeGroupName: "Suppressions",
          canTeammateRequestAccess: true,
        },
      });
    });
  },

  suppressionsGlobalUnsubscribes() {
    import("routes/suppressions/suppressions_global_unsubscribes.tsx").then(
      (view) => {
        view.default({
          layoutController: this.getOption("layoutController"),
          errorRouter: this.errorRouter,
          requiredScopes: "suppression.read",
          accessDeniedOptions: {
            scopeGroupName: "Suppressions",
            canTeammateRequestAccess: true,
          },
        });
      }
    );
  },

  teammates() {
    import("routes/settings_teammates").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        authController: this.getOption("authController"),
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  teammatesConfirmation(queryParams) {
    import("routes/two_factor_authentication/teammates_confirm").then(
      (view) => {
        view.default({
          layoutController: this.getOption("layoutController"),
          basePath: this.getOption("basePath"),
          history: this.getOption("history"),
          queryParams,
        });
      }
    );
  },

  securityCheckup(queryParams) {
    import("routes/two_factor_authentication/security_checkup").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        queryParams,
      });
    });
  },

  errorsExpiredResetPassword() {
    import("routes/errors_expired_reset_password").then((view) => {
      view.default({
        region: this.getOption("region"),
      });
    });
  },

  ipAddressesPurchaseSuccess(queryParams) {
    if (shouldShowIPAddressesBeta()) {
      import(
        "routes/ip_addresses/settings_ip_addresses_purchase_success.tsx"
      ).then((view) => {
        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
        });
      });
    } else {
      import("routes/settings_ip_addresses_purchase_success").then((view) => {
        view.default({
          layoutController: this.getOption("layoutController"),
          queryParams,
        });
      });
    }
  },

  ipAddresses() {
    if (shouldShowIPAddressesBeta()) {
      import("routes/ip_addresses/settings_ip_addresses.tsx").then((view) => {
        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
          sendgridAppStore: this.getOption("sendgridAppStore"),
          basePath: this.getOption("basePath"),
        });
      });
    } else {
      import("routes/settings_ip_addresses.jsx").then((view) => {
        const camAccount = window.Reqres.request("camAccount");

        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
          isTwilioUser: camAccount.billing_org === "tw",
        });
      });
    }
  },

  legacyIpAddresses() {
    if (shouldShowIPAddressesBeta()) {
      import("routes/settings_ip_addresses.jsx").then((view) => {
        const camAccount = window.Reqres.request("camAccount");

        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
          isTwilioUser: camAccount.billing_org === "tw",
        });
      });
    } else {
      window.EventBus.trigger("render404Error");
    }
  },

  emailLogs() {
    if (shouldShowEmailLogs()) {
      import("routes/email_logs").then((view) => {
        view.default({
          // needed for react router
          basePath: this.getOption("basePath"),
          //history: this.getOption("history"),
          layoutController: this.getOption("layoutController"),
        });
      });
    } else {
      window.EventBus.trigger("render404Error");
    }
  },

  expertInsightsPdfDownload(queryParams) {
    import("routes/expert_insights/expert_insights_download.tsx").then(
      (view) => {
        view.default({
          layoutController: this.getOption("layoutController"),
          queryParams,
        });
      }
    );
  },
  expertInsightsLandingPage() {
    const utmParams = getUtmParams();

    import("routes/expert_insights/landing_page.tsx").then((view) => {
      const isReadOnlyTeammate = !(
        window.Reqres.request("hasScopes", "billing.update") ||
        window.Reqres.request("hasScopes", "billing.create")
      );

      view.default({
        layoutController: this.getOption("layoutController"),
        requiredScopes: "messages.read",
        errorRouter: this.errorRouter,
        isReadOnlyTeammate,
        accessDeniedOptions: {
          scopeGroupName: "Email Activity",
          canTeammateRequestAccess: false,
        },
        store: this.getOption("store"),
      });
      analytics.track({
        event: "expert_insights_landing_page_load",
        properties: {
          userId: window.Reqres.request("currentUser").user_id,
          ...utmParams,
        },
      });
    });
  },
  expertInsightsPurchasePage() {
    const utmParams = getUtmParams();

    import("routes/expert_insights/purchase_page.tsx").then((view) => {
      const { user_id: userId, userType } = window.Reqres.request(
        "currentUser"
      );
      const isSubuser = userType === "subuser";

      const isReadOnlyTeammate = !(
        window.Reqres.request("hasScopes", "billing.update") ||
        window.Reqres.request("hasScopes", "billing.create")
      );

      view.default({
        layoutController: this.getOption("layoutController"),
        requiredScopes: ["messages.read", "billing.read"],
        errorRouter: this.errorRouter,
        isReadOnlyTeammate,
        isSubuser,
        userId,
        store: this.getOption("store"),
        accessDeniedOptions: {
          scopeGroupName: "Email Activity",
          canTeammateRequestAccess: false,
        },
      });
      analytics.track({
        event: "expert_insights_purchase_page_load",
        properties: {
          userId,
          ...utmParams,
        },
      });
    });
  },

  emailValidationCsvDownload(queryParams) {
    import("routes/email_validation/email_validation_download.tsx").then(
      (view) => {
        view.default({
          layoutController: this.getOption("layoutController"),
          queryParams,
        });
      }
    );
  },

  emailInsightsDeliverability() {
    import("routes/email_insights/email_insights_deliverability.tsx").then(
      (view) => {
        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
          sendgridAppStore: this.getOption("sendgridAppStore"),
          requiredScopes: "stats.read",
          accessDeniedOptions: {
            scopeGroupName: "Stats",
          },
          basePath: this.getOption("basePath"),
          history: this.getOption("history"),
        });
      }
    );
  },

  ssoSettings(queryParams) {
    import("routes/sso/settings_sso.tsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        sendgridAppStore: this.getOption("sendgridAppStore"),
        requiredScopes: "sso.settings.update",
        // sso scopes are not assignable so there isn't a scopeGroupName included in accessDeniedOptions to request access
        // accessDeniedOptions is still needed to block users without proper scopes
        accessDeniedOptions: {},
        args: { queryParams },
      });
    });
  },

  ssoSettingsConfigureIDP(id) {
    import("routes/sso/settings_sso_configure_idp.tsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        sendgridAppStore: this.getOption("sendgridAppStore"),
        requiredScopes: "sso.settings.update",
        // sso scopes are not assignable so there isn't a scopeGroupName included in accessDeniedOptions to request access
        // accessDeniedOptions is still needed to block users without proper scopes
        accessDeniedOptions: {},
        args: { ssoId: id },
      });
    });
  },

  ssoSettingsConfigureSP(id) {
    import("routes/sso/settings_sso_configure_sp.tsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        sendgridAppStore: this.getOption("sendgridAppStore"),
        requiredScopes: "sso.settings.update",
        // sso scopes are not assignable so there isn't a scopeGroupName included in accessDeniedOptions to request access
        // accessDeniedOptions is still needed to block users without proper scopes
        accessDeniedOptions: {},
        args: { ssoId: id },
      });
    });
  },

  ssoSettingsEdit(id) {
    import("routes/sso/settings_sso_edit.tsx").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        sendgridAppStore: this.getOption("sendgridAppStore"),
        requiredScopes: "sso.settings.update",
        // sso scopes are not assignable so there isn't a scopeGroupName included in accessDeniedOptions to request access
        // accessDeniedOptions is still needed to block users without proper scopes
        accessDeniedOptions: {},
        args: { ssoId: id },
      });
    });
  },

  logout() {
    const shouldShowLogoutPage = shouldShowLogout();

    if (shouldShowLogoutPage) {
      this.getOption("authController").logout(true);
      return;
    }
    this.redirectToLoginAppLogoutPage();
  },

  resetCredentials() {
    import("routes/reset_credentials/reset_credentials.tsx").then((view) => {
      view.default({
        isPublicRoute: true,
        region: this.getOption("region"),
        store: this.getOption("store"),
        basePath: this.getOption("basePath"),
      });
    });
  },

  twoFactorAuthEmailCheckpoint() {
    import("routes/two_factor_authentication/2fa_email_checkpoint").then(
      (view) => {
        view.default({
          errorRouter: this.errorRouter,
          layoutController: this.getOption("layoutController"),
        });
      }
    );
  },

  tokenExchange() {
    import("routes/token_exchange").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        region: this.getOption("region"),
        isPublicRoute: true,
        basePath: this.getOption("basePath"),
        history: this.getOption("history"),
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  twilioSMSPromo() {
    import("routes/twilio_sms_promo/twilio_sms_promo").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        store: this.getOption("store"),
        sendgridAppStore: this.getOption("sendgridAppStore"),
      });
    });
  },

  subuserAccess(queryParams) {
    import("routes/subuser_access").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        region: this.getOption("region"),
        layoutController: this.getOption("layoutController"),
        sendgridAppStore: this.getOption("sendgridAppStore"),
        queryParams,
      });
    });
  },

  oneTimePayment() {
    import("routes/one_time_payment").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        region: this.getOption("region"),
        layoutController: this.getOption("layoutController"),
        sendgridAppStore: this.getOption("sendgridAppStore"),
      });
    });
  },

  resellerSsoError(queryParams) {
    import("routes/reseller_sso_error").then((view) => {
      view.default({
        layoutController: this.getOption("layoutController"),
        errorRouter: this.errorRouter,
        queryParams,
      });
    });
  },

  auth0(queryParams) {
    this.getOption("authController").authCallback(queryParams);
  },

  onboarding() {
    if (shouldShowFreeTrialOnboarding()) {
      import("routes/onboarding").then((view) => {
        return view.default({
          // needed for redux
          store: this.getOption("store"),
          sendgridAppStore: this.getOption("sendgridAppStore"),
          // needed for react router
          basePath: this.getOption("basePath"),
          history: this.getOption("history"),
          layoutController: this.getOption("layoutController"),
        });
      });
    } else {
      window.EventBus.trigger("render404Error");
    }
  },

  notFound404() {
    const currentPath = window.location.pathname;
    // For all routes with /labs as a prefix, we'll redirect to the Labs app with
    // the remaining path appended to the end of the Labs host
    if (currentPath.startsWith("/labs")) {
      window.EventBus.trigger("redirectToLabsApp");
    } else {
      window.EventBus.trigger("render404Error");
    }
  },
});

module.exports = MakoRouter;
