import {
  mapPlan,
  getPriceByName,
  getOverageRate,
  getIncludedCredits,
} from '../../common/adapters';
import {
  MarketingCampaignsTierDto,
  OfferingDto,
  PriceNamesDto,
  OesAccountDto,
} from '../../services/oes/dtos';
import {
  MarketingCampaignsPlan,
  MarketingCampaignsPlanTier,
} from '../../../types/marketingCampaignsPlan';
import { PlanType } from '../../../types/planType';

export function getMarketingCampaignsSendingCredits(
  offering: OfferingDto
): number {
  const { entitlements, pricing } = offering;
  if (pricing) {
    return getIncludedCredits(pricing, PriceNamesDto.MC_SENDS);
  }
  return entitlements.mc_sends_cap_monthly || 0;
}

export function getMarketingCampaignsTier(
  tier?: MarketingCampaignsTierDto
): MarketingCampaignsPlanTier {
  switch (tier) {
    case MarketingCampaignsTierDto.EndOfTrial:
      return MarketingCampaignsPlanTier.EndOfTrial;
    case MarketingCampaignsTierDto.Free:
      return MarketingCampaignsPlanTier.Free;
    case MarketingCampaignsTierDto.Trial:
      return MarketingCampaignsPlanTier.Trial;
    case MarketingCampaignsTierDto.Basic:
      return MarketingCampaignsPlanTier.Basic;
    case MarketingCampaignsTierDto.Advanced:
      return MarketingCampaignsPlanTier.Advanced;
    default:
      return MarketingCampaignsPlanTier.Unknown;
  }
}

export function getMarketingCampaignsContacts(offering: OfferingDto): number {
  const { entitlements, pricing } = offering;
  if (pricing) {
    return getIncludedCredits(pricing, PriceNamesDto.MC_CONTACTS);
  }
  return entitlements.mc_contacts_cap_monthly || 0;
}

export function mapMarketingCampaignsPlan(
  offering: OfferingDto,
  account?: OesAccountDto
): MarketingCampaignsPlan {
  const { pricing, entitlements, is_active } = offering;
  const { mc_tier, mc_tier_rank } = entitlements;
  return {
    ...mapPlan(offering, PlanType.MarketingCampaigns),
    price: getPriceByName(pricing, PriceNamesDto.MC_BASE),
    emailSendingCredits: getMarketingCampaignsSendingCredits(offering),
    emailSendingCreditsDaily: offering.entitlements.mc_sends_cap_daily || 0,
    emailSendingOverageRate: getOverageRate(pricing, PriceNamesDto.MC_SENDS),
    tier: getMarketingCampaignsTier(mc_tier),
    contacts: getMarketingCampaignsContacts(offering),
    contactsDaily: offering.entitlements.mc_contacts_cap_daily || 0,
    contactsOverageRate: getOverageRate(pricing, PriceNamesDto.MC_CONTACTS),
    emailTestingCredits: entitlements.email_testing_cap_monthly || 0,
    signupForms: entitlements.mc_signup_forms_cap_total || 0,
    includesAutomation: entitlements.mc_automation || false,
    hasAdsCanPublish: entitlements.ads_can_publish || false,
    isDisabled: (account && account.entitlements.is_upgrade_locked) || false,
    tierRank: mc_tier_rank,
    isActive: is_active,
    successor: offering.successor,
    allowedTransitionTierRanks:
      entitlements.mc_allowed_transition_tier_ranks ?? [],
    isSignupEligible: Boolean(entitlements.is_signup_eligible),
  } as MarketingCampaignsPlan;
}
