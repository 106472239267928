import React from 'react';
import { Box } from '@twilio-paste/core/box';
import { TrialEnding } from './Banner/TrialEnding';
import { TrialEnded } from './Banner/TrialEnded';
import {
  getWhenTrialEnding,
  getWhenTrialEnded,
  formatTrialEndDate,
} from './utils';
import { EmailApiPlan } from 'src/state/types/emailApiPlan';
import { MarketingCampaignsPlan } from 'src/state/types/marketingCampaignsPlan';
import { withTrialBannerConnector } from './Connector';

export interface Props {
  children?: React.ReactNode;
  emailApiPlan?: EmailApiPlan;
  emailApiFuturePlan?: EmailApiPlan;
  marketingCampaignsPlan?: MarketingCampaignsPlan;
  marketingCampaignsFuturePlan?: MarketingCampaignsPlan;
}

export const TrialNotifications: React.VFC<Props> = ({
  emailApiPlan,
  emailApiFuturePlan,
  marketingCampaignsPlan,
  marketingCampaignsFuturePlan,
}) => {
  if (!emailApiPlan || !marketingCampaignsPlan) {
    return null;
  }

  const eiTrialEndingOnDate = getWhenTrialEnding(
    emailApiPlan,
    emailApiFuturePlan
  );
  const mcTrialEndingOnDate = getWhenTrialEnding(
    marketingCampaignsPlan,
    marketingCampaignsFuturePlan
  );
  const eiTrialEndedOnDate = getWhenTrialEnded(emailApiPlan);
  const mcTrialEndedOnDate = getWhenTrialEnded(marketingCampaignsPlan);
  const trialEndingOnDate = eiTrialEndingOnDate || mcTrialEndingOnDate;
  const trialEndedOnDate = eiTrialEndedOnDate || mcTrialEndedOnDate;

  return (
    <Box>
      {trialEndedOnDate && (
        <TrialEnded trialEndDate={formatTrialEndDate(trialEndedOnDate)} />
      )}
      {trialEndingOnDate && (
        <TrialEnding trialEndDate={formatTrialEndDate(trialEndingOnDate)} />
      )}
    </Box>
  );
};

export default withTrialBannerConnector(TrialNotifications);
