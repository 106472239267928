import { Plan } from '../types/plan';
import { TypedMap } from '../../../src/@types/TypedMap';
import moment from 'moment';
import { EmailApiPlanTier } from '../types/emailApiPlan';
import { PlanType } from '../types/planType';
import { MarketingCampaignsPlanTier } from '../types/marketingCampaignsPlan';

type VersionMap = TypedMap<number>;
type PlanMap = TypedMap<Plan>;

export const getPlansOfLatestVersion = (plans: Plan[]): Plan[] => {
  // Example plan id format: sg.ei.free100.v2
  // Find the .v followed by one or more digits
  // at the end of the string, case insensitive
  const regex = /\.v(\d+)$/i;
  const latestVersions: VersionMap = {};
  const latestPlans: PlanMap = {};
  plans.forEach((plan) => {
    const match = regex.exec(plan.id);
    if (!match) {
      return;
    }
    const [, versionString] = match;
    const version = Number.parseInt(versionString, 10);
    const planKey = plan.id.slice(0, match.index);
    const latestVersion = latestVersions[planKey];
    if (!latestVersion || version > latestVersion) {
      latestVersions[planKey] = version;
      latestPlans[planKey] = plan;
    }
  });
  return Object.values(latestPlans);
};

// Returns the newest active plans
export const getLatestPlans = (plans: Plan[]) => {
  const isActive = (plan: Plan): boolean => {
    // End of trial offerings are not self-serve, but they can still be
    // transitioned-to by the customer, hence they are displayable.
    const isEiEndOfTrial =
      plan.planType === PlanType.EmailApi &&
      plan.tier === EmailApiPlanTier.EndOfTrial;
    const isMcEndOfTrial =
      plan.planType === PlanType.MarketingCampaigns &&
      plan.tier === MarketingCampaignsPlanTier.EndOfTrial;
    const isEndOfTrial = isEiEndOfTrial || isMcEndOfTrial;

    if (!plan.isActive) {
      return false;
    }

    if (plan.tierRank <= 1) {
      return plan.isSignupEligible || isEndOfTrial;
    }

    return plan.isSelfServe && moment(plan.startDate) <= moment().utc();
  };

  return getPlansOfLatestVersion(plans.filter(isActive));
};
