import { AuthedHttp } from '../../helpers/AuthedHttp';
import {
  DowngradePlanRequestDto,
  UgradePlanRequestDto,
  UpdateOfferingsDto,
} from '../dtos';
import { PlanType } from '../types/planType';
import {
  DowngradePlanRequest,
  UpgradePlanRequest,
} from '../types/updatePlanRequest';
import { Routes } from './routes';

import { getEmailApiPlan } from '../middle_tier/common/adapters';
import { NewPlanDto } from '../middle_tier/new_plan/service';
import { OES } from '../middle_tier/services/oes/service';
import { createSubscriptionPayment } from '../payment_methods/service';
import { MarketingCampaignsSubscription } from '../types/marketingCampaignsSubscription';
import { ProductId } from '../types/productId';
import { SubscriptionPayment } from '../types/subscriptionPayment';
import { CamAccountDto } from '../store/rtkQuery/endpoints/camAccount';
import { EmailApiPlanTier } from '../types/emailApiPlan';
import { MarketingCampaignsPlanTier } from '../types/marketingCampaignsPlan';

export const startTrial40k = async (
  marketingCampaignsSubscription: MarketingCampaignsSubscription
): Promise<UpgradePlanRequest> => {
  const upgradePlanRequest = {
    removeEase: false,
    planType: PlanType.EmailApi,
    isUpgrade: true,
    planId: ProductId.SG_EI_TRIAL_40K_V1,
    planName: 'Trial 40K',
  } as UpgradePlanRequest;

  const newPlanRequest = {
    offerings: [
      { name: ProductId.SG_EI_TRIAL_40K_V1 },
      { name: marketingCampaignsSubscription.planId },
    ],
  } as NewPlanDto;

  try {
    const newPlan = await OES.getNewPlanAccount(newPlanRequest);
    upgradePlanRequest.packageId = newPlan.entitlements.compat_combo_uuids[0];
    return upgradePlanRequest;
  } catch (error) {
    console.log('error trying to update to trial 40k', error);
    return Promise.reject('unable to upgrade to trial 40k');
  }
};

export const upgradeWithNewPayment = async (
  subscriptionPayment: SubscriptionPayment,
  upgradePlanRequest: UpgradePlanRequest
) => {
  await createSubscriptionPayment(subscriptionPayment);
  await upgradeService(upgradePlanRequest);
};

export const getCAMAccount = async (): Promise<CamAccountDto> => {
  const response = await AuthedHttp.get<CamAccountDto>(Routes.Accounts);
  if (!response.ok) {
    return Promise.reject('Could not get account information');
  }
  return await response.json();
};

export const upgradeService = async (
  request: UpgradePlanRequest | DowngradePlanRequest
): Promise<string> => {
  try {
    let dto;
    const plan_type = request.planType === PlanType.EmailApi ? 'ei' : 'mc';
    if (request.isUpgrade) {
      const r = request as UpgradePlanRequest;
      if (r.paymentInfo) {
        await createSubscriptionPayment(r.paymentInfo);
      }
      dto = {
        remove_ease: r.removeEase,
        package_id: r.packageId,
        is_upgrade: r.isUpgrade,
        plan_type,
      } as UgradePlanRequestDto;
    } else {
      const d = request as DowngradePlanRequest;
      dto = {
        is_upgrade: false,
        package_id: d.packageId,
        plan_type,
      } as DowngradePlanRequestDto;
      if (d.ip) {
        dto.ip = d.ip;
      }
      if (d.reason) {
        dto.reason = d.reason;
      }
    }

    // When a customer wants to downgrade their plan to EI/MC end-of-trial (non self-serve offerings)
    // they can't do this through the tranditional SSAPI update offerings endpoint. We have to use
    // the cancellation endpoint.
    // https://twilio-engineering.atlassian.net/browse/CLKRAKEN-2544
    if (
      [
        EmailApiPlanTier.EndOfTrial,
        MarketingCampaignsPlanTier.EndOfTrial,
      ].includes(request.planTier)
    ) {
      const cancelDto = {
        product_type: [request.planType === PlanType.EmailApi ? 'ei' : 'mc'],
      };

      const cancelResponse = await AuthedHttp.patch<string>(
        Routes.OfferingsCancel,
        cancelDto
      );
      if (!cancelResponse.ok) {
        return Promise.reject('unable to change subscription');
      }

      return Promise.resolve('success');
    }

    // https://twilio-engineering.atlassian.net/browse/CLRP-3088
    // an account may wish to remove the ease offering when upgrading to a new plan
    // because the ssapi does not yet support updating multiple offerings in a single request
    // we need to handle the case where we need to remove the ease offering separately
    // because we do not set dates on the update offerings request we're relying on
    // the logic in ssapi to set the end date which happens to be the end of the month
    // instead of immediately for the current legacy flow
    let camAccount = window.Reqres.request('camAccount');

    // Tiara may not be available in all contexts (i.e. the onboarding flow does not render Tiara)
    if (!camAccount || Object.keys(camAccount).length === 0) {
      try {
        camAccount = await getCAMAccount();
      } catch (err) {
        console.log(err);
      }
    }

    if (camAccount && camAccount.new_upgrade_enabled) {
      const offeringsUpdate = {
        add: [{ name: request.planId }],
      } as UpdateOfferingsDto;

      const ssapiResponse = await AuthedHttp.patch<string>(
        Routes.OfferingsPatch,
        offeringsUpdate
      );

      if (!ssapiResponse.ok) {
        return Promise.reject('unable to change subscription');
      }

      const account = await OES.getAccount();
      const emailPlan = getEmailApiPlan(account.offerings);

      if (!emailPlan?.name) {
        return Promise.reject('email plan name is undefined');
      }
      const ease = await OES.getAssociatedEaseOffering(emailPlan.name);

      if ('remove_ease' in dto && dto.remove_ease && ease?.name) {
        const easeUpdate = {
          remove: [{ name: ease.name }],
        } as UpdateOfferingsDto;

        const ssapiResponseRemove = await AuthedHttp.patch<string>(
          Routes.OfferingsPatch,
          easeUpdate
        );
        if (!ssapiResponseRemove.ok) {
          return Promise.reject('unable to update ease');
        }
      }

      return Promise.resolve('success');
    }

    const upgradeResponse = await AuthedHttp.put<string>(Routes.Upgrade, dto);
    if (!upgradeResponse.ok) {
      return Promise.reject('unable to change subscription');
    }
    if (request.isUpgrade) {
      // We could fail to charge the cost of the upgrade to the user's card asynchronously.
      // Poll for a payment declined state from OES and show the payment failure banner if needed.
      if (window.SendGridTiara.pollPaymentStatus) {
        window.SendGridTiara.pollPaymentStatus();
      }
    }
    return Promise.resolve('success');
  } catch (err) {
    return Promise.reject('unable to change subscription');
  }
};
