import moment from 'moment';
import { ApiKeyDto } from '../../services/mako_api/dtos';
import {
  OesAccountDto,
  AccountOfferingDto,
  EntitlementsDto,
  OfferingDto,
} from '../../services/oes/dtos';
import {
  MarketingCampaignsAutomationDto,
  LegacyMarketingCampaignsContactsCountDto,
  MarketingCampaignsContactsCountDto,
  MarketingCampaignsSignupFormDto,
  UsageStatsDto,
  SubuserDto,
} from '../../services/mako_api/dtos';
import { UserProducts } from '../../../types/userProducts';
import { mapIps } from '../adapters/ips';
import { mapEase } from '../adapters/ease';
import { mapTestingCreditsSubscription } from '../adapters/testingCredits';
import { mapEmailApiSubscription } from '../adapters/emailApi';
import { mapMarketingCampaignsSubscription } from '../adapters/marketingCampaigns';
import { mapEmailValidation } from '../adapters/emailValidation';
import { mapNextInvoice } from '../adapters/nextInvoice';

export interface UserAdapterData {
  oesAccount: OesAccountDto;
  offerings: OfferingDto[];
  diffEntitlements?: EntitlementsDto;
  usageStats: UsageStatsDto;
  legacyMarketingCampaignsContactsCount: LegacyMarketingCampaignsContactsCountDto;
  marketingCampaignsAutomations: MarketingCampaignsAutomationDto[];
  marketingCampaignsContactsCount: MarketingCampaignsContactsCountDto;
  marketingCampaignsSignupForms: MarketingCampaignsSignupFormDto[];
  apiKeys: ApiKeyDto[];
  subusers: SubuserDto[];
  defaultMCPlan: OfferingDto;
  defaultEIPlan: OfferingDto;
}

export function userProductsAdapter(
  userProducts: UserAdapterData
): UserProducts {
  const {
    oesAccount,
    offerings,
    usageStats,
    legacyMarketingCampaignsContactsCount,
    marketingCampaignsAutomations,
    marketingCampaignsContactsCount,
    marketingCampaignsSignupForms,
    diffEntitlements,
    apiKeys,
    subusers,
    defaultMCPlan,
    defaultEIPlan,
  } = userProducts;
  const { entitlements, pricing } = oesAccount;
  const accountOfferings = oesAccount.offerings;

  const currentAccountOfferings = accountOfferings.filter(
    (offering: AccountOfferingDto) =>
      moment(offering.start_date) <= moment().utc()
  );
  const futureAccountOfferings = accountOfferings.filter(
    (offering: AccountOfferingDto) =>
      moment(offering.start_date) > moment().utc()
  );

  /**
   * needed for testing credits
   */
  const futureEndingOfferings = accountOfferings.filter(
    (offering: AccountOfferingDto) => {
      if (offering.end_date) {
        const startOfNextMonth = getStartOfNextMonth();
        const endDate = moment(offering.end_date).utc().format('YYYY-MM-DD');
        return startOfNextMonth === endDate;
      }
    }
  );

  const ips = mapIps(
    currentAccountOfferings,
    futureAccountOfferings,
    offerings,
    entitlements,
    diffEntitlements
  );

  const ease = mapEase(
    currentAccountOfferings,
    futureAccountOfferings,
    entitlements
  );

  const testingCreditsSubscription = mapTestingCreditsSubscription(
    currentAccountOfferings,
    futureAccountOfferings,
    offerings,
    entitlements,
    futureEndingOfferings
  );

  const emailApiSubscription = mapEmailApiSubscription({
    currentOfferings: currentAccountOfferings,
    futureOfferings: futureAccountOfferings,
    entitlements,
    usageStats,
    legacyMarketingCampaignsContactsCount,
    defaultEIPlan,
  });

  const marketingCampaignsSubscription = mapMarketingCampaignsSubscription({
    currentOfferings: currentAccountOfferings,
    futureOfferings: futureAccountOfferings,
    usageStats,
    marketingCampaignsContactsCount,
    marketingCampaignsSignupForms,
    marketingCampaignsAutomations,
    entitlements,
    subusers,
    defaultMCPlan,
  });

  const emailValidation = mapEmailValidation(
    oesAccount,
    apiKeys,
    usageStats,
    diffEntitlements
  );

  const nextInvoice = mapNextInvoice(pricing);

  return {
    ips,
    ease,
    testingCreditsSubscription,
    emailApiSubscription,
    marketingCampaignsSubscription,
    nextInvoice,
    emailValidation,
  } as UserProducts;
}

function getStartOfNextMonth() {
  const month = 'month';
  const nextMonth = moment()
    .add(1, month)
    .startOf(month)
    .utc()
    .format('YYYY-MM-DD');
  return nextMonth;
}
