import { EmailApiSubscription } from 'src/state/types/emailApiSubscription';
import { Cache } from '../cache';
import { isEmailApiPlan, isMarketingCampaignsPlan } from '../common/adapters';
import { mapEmailApiPlan } from '../products/adapters/emailApiPlans';
import { mapMarketingCampaignsPlan } from '../products/adapters/marketingCampaignsPlans';
import {
  AccountOfferingDto,
  OesAccountDto,
  OfferingDto,
} from '../services/oes/dtos';
import { OES } from '../services/oes/service';
import { CacheKeys } from '../cache/keys';
import { AccountOfferings } from './actions';
import moment from 'moment';
import { Plan } from 'src/state/types/plan';
import { MarketingCampaignsSubscription } from 'src/state/types/marketingCampaignsSubscription';
import { EmailApiPlan } from 'src/state/types/emailApiPlan';
import { MarketingCampaignsPlan } from 'src/state/types/marketingCampaignsPlan';

export const getFutureOffering = <T extends Plan>(
  plans: T[]
): T | undefined => {
  return plans.find((plan) => moment(plan.startDate) > moment().utc());
};

export const getCurrentOffering = <T extends Plan>(
  plans: T[]
): T | undefined => {
  return plans.find((plan) => moment(plan.startDate) <= moment().utc());
};

export const getEiOfferingsOnAccountWithCache = (
  userId: number,
  catalog: OfferingDto[],
  offerings: EmailApiPlan[],
  account: OesAccountDto
): AccountOfferings['emailApi'] => {
  const cachedOffering = Cache.resolveIfNotStale<EmailApiSubscription>(
    userId,
    CacheKeys.EmailApiSubscription
  );

  if (!cachedOffering) {
    const current = getCurrentOffering(offerings);
    const future = getFutureOffering(offerings);
    return { current, future };
  }

  const currentOffering = catalog.find(
    (offering) => offering.name === cachedOffering.planId
  );
  const futureOffering = catalog.find(
    (offering) => offering.name === cachedOffering.downgradePlanId
  );

  const eiOfferings: AccountOfferings['emailApi'] = {};
  if (currentOffering) {
    eiOfferings.current = {
      ...mapEmailApiPlan(currentOffering, account),
      startDate: cachedOffering.startDate,
    };
  }
  if (futureOffering && cachedOffering.downgradePlanStartDate) {
    eiOfferings.future = {
      ...mapEmailApiPlan(futureOffering, account),
      startDate: cachedOffering.downgradePlanStartDate,
    };
  }

  return eiOfferings;
};

export const getMcOfferingsOnAccountWithCache = (
  userId: number,
  catalog: OfferingDto[],
  offerings: MarketingCampaignsPlan[],
  account: OesAccountDto
): AccountOfferings['marketingCampaigns'] => {
  const cachedOffering = Cache.resolveIfNotStale<
    MarketingCampaignsSubscription
  >(userId, CacheKeys.MarketingCampaignSubscription);

  if (!cachedOffering) {
    const current = getCurrentOffering(offerings);
    const future = getFutureOffering(offerings);
    return { current, future };
  }

  const currentOffering = catalog.find(
    (offering) => offering.name === cachedOffering.planId
  );
  const futureOffering = catalog.find(
    (offering) => offering.name === cachedOffering.downgradePlanId
  );

  const mcOfferings: AccountOfferings['marketingCampaigns'] = {};
  if (currentOffering) {
    mcOfferings.current = {
      ...mapMarketingCampaignsPlan(currentOffering, account),
      startDate: cachedOffering.startDate,
    };
  }
  if (futureOffering && cachedOffering.downgradePlanStartDate) {
    mcOfferings.future = {
      ...mapMarketingCampaignsPlan(futureOffering, account),
      startDate: cachedOffering.downgradePlanStartDate,
    };
  }

  return mcOfferings;
};

export const getAccountOfferings = async (
  userId: number
): Promise<AccountOfferings> => {
  const account = await OES.getAccount();
  const { offerings } = await OES.getOfferings();
  const offeringMap = account.offerings.reduce<
    Record<string, AccountOfferingDto>
  >((map, offering) => {
    map[offering.name] = { ...offering };
    return map;
  }, {});

  const emailApiOfferings = offerings
    .filter(isEmailApiPlan)
    .filter((offering) => offeringMap[offering.name])
    .map((offering) => {
      const plan = mapEmailApiPlan(offering, account);
      return {
        ...plan,
        startDate: offeringMap[offering.name]?.start_date,
      };
    });
  const marketingCampaignsOfferings = offerings
    .filter(isMarketingCampaignsPlan)
    .filter((offering) => offeringMap[offering.name])
    .map((offering) => {
      const plan = mapMarketingCampaignsPlan(offering, account);
      return {
        ...plan,
        startDate: offeringMap[offering.name]?.start_date,
      };
    });

  return {
    catalog: offerings,
    emailApi: getEiOfferingsOnAccountWithCache(
      userId,
      offerings,
      emailApiOfferings,
      account
    ),
    marketingCampaigns: getMcOfferingsOnAccountWithCache(
      userId,
      offerings,
      marketingCampaignsOfferings,
      account
    ),
  };
};
