import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, concatMap } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
// actions
import { Actions as AccountOfferingActions } from './actions';
import { Actions as EmailApiActions } from '../../email_api_subscription/actions';
import { ActionTypes as EmailApiActionTypes } from '../../email_api_subscription/actionTypes';
import { Actions as MarketingCampaignsActions } from '../../marketing_campaigns_subscription/actions';
import { ActionTypes as MarketingCampaignActionTypes } from '../../marketing_campaigns_subscription/actionTypes';
// types
import { ActionTypes } from './actionTypes';
import { MakoStateType } from '../../types/makoStateType';
// service
import { getAccountOfferings } from './service';

// Whenever the frontend changes the EI subscription
export const updateEmailApiOfferingEpic = (
  action$: ActionsObservable<
    ReturnType<typeof EmailApiActions.getEmailApiSubscriptionSuccess>
  >,
  state$: StateObservable<MakoStateType>
): Observable<
  ReturnType<typeof AccountOfferingActions.updateEmailApiOffering>
> => {
  return action$.pipe(
    ofType(EmailApiActionTypes.GetEmailApiSubscriptionSuccess),
    concatMap((action) => {
      const { emailApi } = state$.value.accountOfferings;
      const { current, future } = emailApi;
      const emailApiSubscription = action.payload;

      // If there are no updates we don't need to fire off an action
      if (
        emailApiSubscription.planId === current?.id &&
        emailApiSubscription.downgradePlanId === future?.id
      ) {
        return [];
      }

      return [
        AccountOfferingActions.updateEmailApiOffering({
          currentOffering: emailApiSubscription.planId,
          currentOfferingStartDate: emailApiSubscription.startDate,
          futureOffering: emailApiSubscription.downgradePlanId,
          futureStartDate: emailApiSubscription.downgradePlanStartDate,
        }),
      ];
    })
  );
};

// Whenever the frontend changes the MC subscription
export const updateMarketingCampaignsOfferingEpic = (
  action$: ActionsObservable<
    ReturnType<
      typeof MarketingCampaignsActions.getMarketingCampaignsSubscriptionSuccess
    >
  >,
  state$: StateObservable<MakoStateType>
): Observable<
  ReturnType<typeof AccountOfferingActions.updateMarketingCampaignsOffering>
> => {
  return action$.pipe(
    ofType(
      MarketingCampaignActionTypes.GetMarketingCampaignsSubscriptionSuccess
    ),
    concatMap((action) => {
      const { marketingCampaigns } = state$.value.accountOfferings;
      const { current, future } = marketingCampaigns;
      const marketingCampaignsSubscription = action.payload;

      // If there are no updates we don't need to fire off an action
      if (
        marketingCampaignsSubscription.planId === current?.id &&
        marketingCampaignsSubscription.downgradePlanId === future?.id
      ) {
        return [];
      }

      return [
        AccountOfferingActions.updateMarketingCampaignsOffering({
          currentOffering: marketingCampaignsSubscription.planId,
          currentOfferingStartDate: marketingCampaignsSubscription.startDate,
          futureOffering: marketingCampaignsSubscription.downgradePlanId,
          futureStartDate:
            marketingCampaignsSubscription.downgradePlanStartDate,
        }),
      ];
    })
  );
};

export const accountOfferingsEpic = (
  action$: ActionsObservable<
    ReturnType<typeof AccountOfferingActions.getAccountOfferings>
  >,
  state$: StateObservable<MakoStateType>
): Observable<
  | ReturnType<typeof AccountOfferingActions.getAccountOfferingsSuccess>
  | ReturnType<typeof AccountOfferingActions.getAccountOfferingsFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.GetAccountOfferingsRequest),
    concatMap(() =>
      from(getAccountOfferings(state$.value.accountProfile.userId)).pipe(
        concatMap((data) => [
          AccountOfferingActions.getAccountOfferingsSuccess(data),
        ]),
        catchError(() => {
          return of(AccountOfferingActions.getAccountOfferingsFailure());
        })
      )
    )
  );
};

export default [
  accountOfferingsEpic,
  updateEmailApiOfferingEpic,
  updateMarketingCampaignsOfferingEpic,
];
