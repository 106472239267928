import React from 'react';
import { Topbar, TopbarActions } from '@twilio-paste/core/topbar';
import {
  UserDialog,
  UserDialogContainer,
  UserDialogList,
  UserDialogListItem,
  UserDialogSeparator,
  UserDialogUserEmail,
  UserDialogUserInfo,
  UserDialogUserName,
  useUserDialogListState,
} from '@twilio-paste/core/user-dialog';
import {
  ProductSwitcher,
  ProductSwitcherButton,
  ProductSwitcherItem,
  useProductSwitcherState,
} from '@twilio-paste/core/product-switcher';
import { ProductCommsIcon } from '@twilio-paste/icons/cjs/ProductCommsIcon';
import { ProductSegmentIcon } from '@twilio-paste/icons/cjs/ProductSegmentIcon';
import { ProductSendGridIcon } from '@twilio-paste/icons/cjs/ProductSendGridIcon';
import { UserIcon } from '@twilio-paste/icons/esm/UserIcon';
import { LogOutIcon } from '@twilio-paste/icons/esm/LogOutIcon';
import { DocumentationIcon } from '@twilio-paste/icons/esm/DocumentationIcon';
import { SupportIcon } from '@twilio-paste/icons/esm/SupportIcon';
import { Button } from '@twilio-paste/core/button';
import APIConfig from '../../apiconfig';
import { Text } from '@twilio-paste/core/text';
import { MenuSeparator } from '@twilio-paste/core/menu';
import { Box } from '@twilio-paste/box';
import { showGlobalNavBarUpdates } from 'src/helpers/featureFlags/showGlobalNavBarUpdates';
import AccountSwitcherWrapper from 'src/components/Navbar/AccountSwitcher/AccountSwitcherWrapper';
import {
  useAppDispatch,
  useAppSelector,
} from 'src/state/store/sendgridAppStore';
import { selectIsSubuser } from 'src/state/store/rtkQuery/endpoints/account';
import {
  selectFriendlyName,
  selectUserName,
} from 'src/state/store/rtkQuery/endpoints/accountProfile';
import words from 'src/components/Navbar/en';
import SendgridLogo from '../../../public/assets_mako/images/logo/logo.png';
import { TrialUpgradeBadge } from './Trials/TrialUpgradeBadge';
import { showFreeTrialsInConsole } from 'src/helpers/featureFlags/showFreeTrialsInConsole';
import {
  isEiEndOfTrial,
  isEiOnTrial,
  isMcEndOfTrial,
  isMcOnTrial,
} from 'src/helpers/offerings/trials';
import { EmailApiPlan } from 'src/state/types/emailApiPlan';
import { MarketingCampaignsPlan } from 'src/state/types/marketingCampaignsPlan';
import { withGlobalBannerConnector } from './Connector';

export interface Props {
  showProductSwitcher: boolean;
  name: string;
  email: string;
  emailApiPlan?: EmailApiPlan;
  marketingCampaignsPlan?: MarketingCampaignsPlan;
}

export const Navbar: React.VFC<Props> = ({
  showProductSwitcher,
  name,
  email,
  emailApiPlan,
  marketingCampaignsPlan,
}) => {
  const dispatch = useAppDispatch();
  const userDialogList = useUserDialogListState();
  const productSwitcher = useProductSwitcherState();
  const encodedEmail = encodeURIComponent(email);
  const twilioConsoleUrl = `https://${APIConfig.twilioHost}/unified_login/start?email=${encodedEmail}&context=switch&source=sendgrid`;
  const userSettingsUrl = `${twilioConsoleUrl}&deeplink=user-settings`;
  const segmentUrl = `https://${APIConfig.segmentHost}/unified_login/start?email=${encodedEmail}&context=switch&source=sendgrid`;
  const accountsHomeLinkLegacy = `${twilioConsoleUrl}&deeplink=user-accounts`;
  const shouldShowGlobalNavBarUpdates = showGlobalNavBarUpdates();
  const shouldShowFreeTrials = showFreeTrialsInConsole();
  const isSubuser = useAppSelector(selectIsSubuser);
  const userName = useAppSelector(selectUserName);
  const friendlyName = useAppSelector(selectFriendlyName);
  const accountName = isSubuser ? userName : friendlyName;
  const allSubusersUrl = `subuser/access`;

  const eiEndOfTrial = emailApiPlan ? isEiEndOfTrial(emailApiPlan) : false;
  const mcEndOfTrial = marketingCampaignsPlan
    ? isMcEndOfTrial(marketingCampaignsPlan)
    : false;
  const eiOnTrial = emailApiPlan ? isEiOnTrial(emailApiPlan) : false;
  const mcOnTrial = marketingCampaignsPlan
    ? isMcOnTrial(marketingCampaignsPlan)
    : false;
  const showEiUpgrade = Boolean(eiEndOfTrial || eiOnTrial);
  const showMcUpgrade = Boolean(mcEndOfTrial || mcOnTrial);

  return (
    <Topbar id="topbar">
      {shouldShowGlobalNavBarUpdates && (
        <TopbarActions justify="start">
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            columnGap={'space40'}
          >
            <img
              height={'24px'}
              width={'24px'}
              alt={words.logoAlt}
              src={SendgridLogo}
            />
            <Text as="h3" fontSize="fontSize20" style={{ fontWeight: 700 }}>
              {words.twilioSGBranding}
            </Text>
            {/*Todo: Uncomment this line when we the Twilio home functionality is ready*/}
            {/*<TwilioHomeButton homeLink={accountsHomeLinkLegacy} />*/}
            <AccountSwitcherWrapper
              friendlyName={accountName}
              isSubuser={isSubuser}
              isTrial={false}
              accountsHomeLink={accountsHomeLinkLegacy}
              allSubusersLink={allSubusersUrl}
            />
            {(showEiUpgrade || showMcUpgrade) && shouldShowFreeTrials && (
              <TrialUpgradeBadge
                showEiUpgrade={showEiUpgrade}
                showMcUpgrade={showMcUpgrade}
              />
            )}
          </Box>
        </TopbarActions>
      )}
      <TopbarActions justify="end">
        {showProductSwitcher && (
          <>
            <ProductSwitcherButton
              {...productSwitcher}
              i18nButtonLabel="Switch products"
            />
            <ProductSwitcher
              {...productSwitcher}
              aria-label="Available products"
            >
              <ProductSwitcherItem
                {...productSwitcher}
                name="product"
                value="twilio"
                checked={false}
                onChange={() => {
                  window.location.href = twilioConsoleUrl;
                }}
                productName="Twilio Comms"
                productStrapline="SMS, Voice & Video"
                productIcon={
                  <ProductCommsIcon
                    decorative
                    color="colorTextBrandHighlight"
                    size="sizeIcon50"
                  />
                }
              />
              <MenuSeparator />
              <ProductSwitcherItem
                {...productSwitcher}
                name="product"
                value="segment"
                checked={false}
                onChange={() => {
                  window.location.href = segmentUrl;
                }}
                productName="Twilio Segment"
                productStrapline="Customer data platform"
                productIcon={
                  <Text as="span" style={{ color: '#6ADDB2' }}>
                    <ProductSegmentIcon decorative size="sizeIcon50" />
                  </Text>
                }
              />
              <MenuSeparator />
              <ProductSwitcherItem
                {...productSwitcher}
                name="product"
                value="sendgrid"
                checked={true}
                productName="Twilio SendGrid"
                productStrapline="Email API & Marketing Campaigns"
                productIcon={
                  <Text as="span" style={{ color: '#51A9E3' }}>
                    <ProductSendGridIcon decorative size="sizeIcon50" />
                  </Text>
                }
              />
            </ProductSwitcher>
          </>
        )}
        <UserDialogContainer
          name="User Name"
          icon={UserIcon}
          baseId="i-am-user-dialog"
        >
          <UserDialog aria-label="user menu" data-testid="basic-user-dialog">
            <UserDialogUserInfo tabIndex={-1}>
              <span style={{ wordBreak: 'break-all' }}>
                <UserDialogUserName>{name}</UserDialogUserName>
              </span>
              <span style={{ wordBreak: 'break-all' }}>
                <UserDialogUserEmail>{email}</UserDialogUserEmail>
              </span>
              <Button
                onClick={() => {
                  window.open(userSettingsUrl, '_blank');
                }}
                variant="link"
                tabIndex={-1}
              >
                <span style={{ fontWeight: 400 }}>User Settings</span>
              </Button>
            </UserDialogUserInfo>
            <UserDialogList {...userDialogList} aria-label="User menu actions">
              <UserDialogListItem
                {...userDialogList}
                tabIndex={-1}
                href="/account/details"
              >
                <UserIcon decorative />
                SendGrid Preferences
              </UserDialogListItem>
              <UserDialogSeparator />
              <UserDialogListItem
                {...userDialogList}
                tabIndex={-1}
                href="/guide"
              >
                <DocumentationIcon decorative />
                Setup Guide
              </UserDialogListItem>
              <span
                onClick={() => {
                  window.open(
                    'https://support.sendgrid.com/hc/en-us',
                    '_blank'
                  );
                }}
              >
                <UserDialogListItem {...userDialogList} tabIndex={-1}>
                  <SupportIcon decorative />
                  Help & Support
                </UserDialogListItem>
              </span>
              <UserDialogSeparator />
              <UserDialogListItem
                {...userDialogList}
                tabIndex={-1}
                href="/logout"
              >
                <LogOutIcon decorative />
                Log out
              </UserDialogListItem>
            </UserDialogList>
          </UserDialog>
        </UserDialogContainer>
      </TopbarActions>
    </Topbar>
  );
};

export default withGlobalBannerConnector(Navbar);
