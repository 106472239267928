export enum EmailApiTierDto {
  Free = 'free',
  Essentials = 'essentials',
  Pro = 'pro',
  Premier = 'premier',
  Trial = 'trial',
  EndOfTrial = 'end-of-trial',
}

export enum MarketingCampaignsTierDto {
  Free = 'free',
  Basic = 'basic',
  Advanced = 'advanced',
  Trial = 'trial',
  EndOfTrial = 'end-of-trial',
}

export enum PriceNamesDto {
  EI_BASE = 'ei_base',
  EI_SENDS = 'ei_sends',
  MC_BASE = 'mc_base',
  MC_SENDS = 'mc_sends',
  MC_CONTACTS = 'mc_contacts',
  IP_BASE = 'ip_base',
  EASE_BASE = 'ease_base',
  TC_BASE = 'tc_base',
}

export enum OfferingTypeDto {
  PACKAGE = 'package',
  ADDON = 'addon',
  DISCOUNT = 'discount',
  SUMMARY = 'summary',
  FLAG = 'flag',
}

export interface DisplayDto {
  en_us: string;
}

export interface EntitlementsDto {
  org?: string;
  ease_prefer_legacy_email_activity?: boolean;
  ei_trial_eligible?: boolean;
  ei_sends_cap_daily?: number;
  ei_sends_cap_monthly?: number;
  ei_tier?: EmailApiTierDto;
  mc_tier?: MarketingCampaignsTierDto;
  ip_cap_monthly?: number;
  ip_count?: number;
  teammates_cap_total?: number;
  users_cap_total?: number;
  webhooks_workspace_cap_total?: number;
  ease_days?: number;
  compat_pkg_ip_count?: number;
  compat_ease_pkg_offerings?: string[];
  compat_payment_declined?: boolean;
  email_testing_cap_monthly?: number;
  mc_contacts_cap_daily?: number;
  mc_contacts_cap_monthly?: number;
  mc_sends_cap_daily?: number;
  mc_sends_cap_monthly?: number;
  mc_signup_forms_cap_total?: number;
  mc_automation?: boolean;
  compat_combo_uuids: string[];
  ei_tier_rank?: number;
  mc_tier_rank?: number;
  email_validations_cap_daily?: number;
  email_validations_cap_monthly?: number;
  compat_self_serve?: boolean;
  can_have_email_testing_addon?: boolean;
  ease_addon_eligible?: boolean;
  is_upgrade_locked?: boolean;
  ei_allowed_transition_tier_ranks?: number[];
  mc_allowed_transition_tier_ranks?: number[];
  is_signup_eligible?: boolean;
  [index: string]:
    | string
    | boolean
    | number
    | EmailApiTierDto
    | MarketingCampaignsTierDto
    | string[]
    | number[]
    | undefined;
}

export interface PricingTierDto {
  size?: number;
  amount: string;
  min?: number;
  max?: number;
  is_infinite?: boolean;
}

export interface ProductPricingDto {
  [index: string]: PriceDto;
}

export interface PriceDto {
  amount?: string;
  tiers?: PricingTierDto[];
}

export interface PricingDto {
  currency: string;
  products: ProductPricingDto;
}

export interface AccountPricingDto extends PricingDto {
  total: {
    one_time: string;
    discount: string;
    recurring: string;
    sum: string;
  };
}

export interface OfferingDto {
  name: string;
  display: DisplayDto;
  entitlements: EntitlementsDto;
  pricing: PricingDto;
  type: OfferingTypeDto;
  successor: string;
  start_date: string;
  is_active: boolean;
}

export interface OfferingsDto {
  offerings: OfferingDto[];
}

export interface OesAccountDto {
  account: string;
  key: string;
  version: number;
  region: string;
  created_at: string;
  offerings: AccountOfferingDto[];
  entitlements: EntitlementsDto;
  pricing: AccountPricingDto;
}

export interface AccountOfferingDto {
  name: string;
  quantity: number;
  start_date: string;
  end_date?: string;
  type: OfferingTypeDto;
}
