import { ActionTypes } from './actionTypes';
import { OesAccountDto } from '../middle_tier/services/oes/dtos';
import { ActionsUnion, createAction } from '../actionBuilder';

export const Actions = {
  cancelAccountDiffRequest: () =>
    createAction(ActionTypes.CancelAccountDiffRequest),
  cancelAccountDiffError: () =>
    createAction(ActionTypes.CancelAccountDiffError),
  cancelAccountDiffSuccess: (cancelAccountDiff: OesAccountDto) =>
    createAction(ActionTypes.CancelAccountDiffSuccess, cancelAccountDiff),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
