import { formRedirectRoute } from 'src/helpers/navigation';

export const content = {
  trialEnded: (date: string) => `Your free 60 day trial ended on ${date}.`,
  upgradeLink: {
    text: 'Upgrade',
    href: formRedirectRoute('account/products'),
  },
  guideLink: {
    text: 'guide',
    href: formRedirectRoute('guide'),
  },
  trialEnding: (endDate: string) => ({
    infoSentence: `Your free trial ends on ${endDate}.`,
    actionSentence: 'now to get the most out of your email program. Follow our',
    finalSentence: 'for EmailAPI or Marketing Campaigns.',
  }),
};
