import { combineEpics } from 'redux-observable';

import addExpertInsightsEpic from './add_expert_insights/epics';
import addExpertServiceImplementationEpic from './add_expert_service_implementation/epics';
import cancelAccountDiffEpics from './cancel_account_diff/epics';
import cancelAccountEpics from './cancel_account/epics';
import changePlanEpics from './update_plan/epics';
import contactsEpics from './contacts/epics';
import easeEpics from './ease_subscription/epics';
import expertServicesImplementationEpics from './expert_services_implementation/epics';
import expertServicesOngoingEpics from './expert_services_ongoing/epics';
import paymentMethodEpics from './payment_methods/epics';
import productsEpics from './middle_tier/products/epics';
import removeExpertInsightsEpics from './remove_expert_insights/epics';
import subusersEpics from './subusers/epics';
import teammateRequestAccessEpics from './teammate_request_access/epics';
import teammatesEpics from './teammates/epics';
import timezoneEpics from './time_zone/epics';
import timezoneOptionsEpics from './time_zone_options/epics';
import upgradeEligibilityEpics from './upgrade_eligibility/epics';
import userEpics from './account_profile/epics';
import userAccountEpics from './user/epics';
import userPackageEpics from './user_package/epics';
import userProductsEpics from './middle_tier/user_products/epics';
import accountOfferingsEpics from './middle_tier/account_offerings/epics';
import subscriptionChangesEpics from './subscription_changes/epics';
import updateTestingCreditsSubscriptionEpic from './testing_credits_subscription/epics';
import emailApiUsageEpics from './email_api_usage/epics';

// These lists are really long and easily duplicated.
// Please try to keep them alphabetized to prevent that.
export const rootEpic = combineEpics(
  ...addExpertInsightsEpic,
  ...addExpertServiceImplementationEpic,
  ...cancelAccountDiffEpics,
  ...cancelAccountEpics,
  ...changePlanEpics,
  ...contactsEpics,
  ...easeEpics,
  ...expertServicesImplementationEpics,
  ...expertServicesOngoingEpics,
  ...paymentMethodEpics,
  ...productsEpics,
  ...removeExpertInsightsEpics,
  ...subusersEpics,
  ...teammateRequestAccessEpics,
  ...teammatesEpics,
  ...timezoneEpics,
  ...timezoneOptionsEpics,
  ...upgradeEligibilityEpics,
  ...userEpics,
  ...userAccountEpics,
  ...userPackageEpics,
  ...userProductsEpics,
  ...subscriptionChangesEpics,
  ...updateTestingCreditsSubscriptionEpic,
  ...emailApiUsageEpics,
  ...accountOfferingsEpics
);
