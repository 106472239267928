export enum ProductId {
  FLAG_EI_PRE_TRIAL_V1 = 'flag.ei.pre-trial.v1',
  FLAG_MC_CLASSIC_PRE_TRIAL_V1 = 'flag.mc.classic-pre-trial.v1',
  SG_DISCOUNT_COMMDEV_TIER_1_V1 = 'sg.discount.commdev-tier-1.v1',
  SG_DISCOUNT_COMMDEV_TIER_2_V1 = 'sg.discount.commdev-tier-2.v1',
  SG_DISCOUNT_COMMDEV_TIER_3_V1 = 'sg.discount.commdev-tier-3.v1',
  SG_DISCOUNT_COVID19_25PERCENT_V1 = 'sg.discount.covid19-25percent.v1',
  SG_DISCOUNT_COVID19_50PERCENT_V1 = 'sg.discount.covid19-50percent.v1',
  SG_DISCOUNT_COVID19_75PERCENT_V1 = 'sg.discount.covid19-75percent.v1',
  SG_DISCOUNT_COVID19_V1 = 'sg.discount.covid19.v1',
  SG_DISCOUNT_CROSS_SELL_PROMOTION_100PERCENT_V1 = 'sg.discount.cross-sell-promotion-100percent.v1',
  SG_DISCOUNT_CROSS_SELL_PROMOTION_50PERCENT_V1 = 'sg.discount.cross-sell-promotion-50percent.v1',
  SG_DISCOUNT_CUSTOMER_SUPPORT_V1 = 'sg.discount.customer-support.v1',
  SG_DISCOUNT_DEVNET_249_V1 = 'sg.discount.devnet-249.v1',
  SG_DISCOUNT_EMPLOYEE_INTERNAL_USE_V1 = 'sg.discount.employee-internal-use.v1',
  SG_DISCOUNT_NONPROFIT_25PERCENT_V1 = 'sg.discount.nonprofit-25percent.v1',
  SG_DISCOUNT_NONPROFIT_V1 = 'sg.discount.nonprofit.v1',
  SG_DISCOUNT_RESELLER_DEPRECATION_BRONZE_ESSENTIALS_40K_V1 = 'sg.discount.reseller-deprecation-bronze-essentials-40k.v1',
  SG_DISCOUNT_RESELLER_DEPRECATION_GOLD_PRO_300K_V1 = 'sg.discount.reseller-deprecation-gold-pro-300k.v1',
  SG_DISCOUNT_RESELLER_DEPRECATION_PLATINUM_PRO_700K_V1 = 'sg.discount.reseller-deprecation-platinum-pro-700k.v1',
  SG_DISCOUNT_RESELLER_DEPRECATION_SILVER_PRO_100K_V1 = 'sg.discount.reseller-deprecation-silver-pro-100k.v1',
  SG_EI_BRONZE_V1 = 'sg.ei.bronze.v1',
  SG_EI_ESSENTIALS_100K_V1 = 'sg.ei.essentials-100k.v1',
  SG_EI_ESSENTIALS_100K_V2 = 'sg.ei.essentials-100k.v2',
  SG_EI_ESSENTIALS_100K_V3 = 'sg.ei.essentials-100k.v3',
  SG_EI_ESSENTIALS_40K_V1 = 'sg.ei.essentials-40k.v1',
  SG_EI_ESSENTIALS_40K_V2 = 'sg.ei.essentials-40k.v2',
  SG_EI_ESSENTIALS_50K_V1 = 'sg.ei.essentials-50k.v1',
  SG_EI_ESSENTIALS_SUMMARY_V1 = 'sg.ei.essentials-summary.v1',
  SG_EI_FREE_100_V1 = 'sg.ei.free-100.v1',
  SG_EI_FREE_12K_V1 = 'sg.ei.free-12k.v1',
  SG_EI_FREE_TRIAL_100_V1 = 'sg.ei.trial.v1',
  SG_EI_END_OF_TRIAL_V1 = 'sg.ei.end-of-trial.v1',
  SG_EI_FREE_SUMMARY_V1 = 'sg.ei.free-summary.v1',
  SG_EI_GOLD_V1 = 'sg.ei.gold.v1',
  SG_EI_GOOGLE_FREE_V1 = 'sg.ei.google-free.v1',
  SG_EI_LITE_V1 = 'sg.ei.lite.v1',
  SG_EI_MC_CLASSIC_ADDON_V1 = 'sg.ei.mc-classic-addon.v1',
  SG_EI_PLATINUM_V1 = 'sg.ei.platinum.v1',
  SG_EI_PREMIER_100M_V1 = 'sg.ei.premier-100m.v1',
  SG_EI_PREMIER_100M_V2 = 'sg.ei.premier-100m.v2',
  SG_EI_PREMIER_10M_V1 = 'sg.ei.premier-10m.v1',
  SG_EI_PREMIER_10M_V2 = 'sg.ei.premier-10m.v2',
  SG_EI_PREMIER_15M_V1 = 'sg.ei.premier-15m.v1',
  SG_EI_PREMIER_15M_V2 = 'sg.ei.premier-15m.v2',
  SG_EI_PREMIER_200M_V1 = 'sg.ei.premier-200m.v1',
  SG_EI_PREMIER_200M_V2 = 'sg.ei.premier-200m.v2',
  SG_EI_PREMIER_20M_V1 = 'sg.ei.premier-20m.v1',
  SG_EI_PREMIER_20M_V2 = 'sg.ei.premier-20m.v2',
  SG_EI_PREMIER_25M_V1 = 'sg.ei.premier-25m.v1',
  SG_EI_PREMIER_25M_V2 = 'sg.ei.premier-25m.v2',
  SG_EI_PREMIER_2P5M_V1 = 'sg.ei.premier-2p5m.v1',
  SG_EI_PREMIER_300M_V1 = 'sg.ei.premier-300m.v1',
  SG_EI_PREMIER_300M_V2 = 'sg.ei.premier-300m.v2',
  SG_EI_PREMIER_3M_V1 = 'sg.ei.premier-3m.v1',
  SG_EI_PREMIER_400M_V1 = 'sg.ei.premier-400m.v1',
  SG_EI_PREMIER_400M_V2 = 'sg.ei.premier-400m.v2',
  SG_EI_PREMIER_500M_V1 = 'sg.ei.premier-500m.v1',
  SG_EI_PREMIER_500M_V2 = 'sg.ei.premier-500m.v2',
  SG_EI_PREMIER_50M_V1 = 'sg.ei.premier-50m.v1',
  SG_EI_PREMIER_50M_V2 = 'sg.ei.premier-50m.v2',
  SG_EI_PREMIER_5M_V1 = 'sg.ei.premier-5m.v1',
  SG_EI_PREMIER_5M_V2 = 'sg.ei.premier-5m.v2',
  SG_EI_PREMIER_600M_V1 = 'sg.ei.premier-600m.v1',
  SG_EI_PREMIER_600M_V2 = 'sg.ei.premier-600m.v2',
  SG_EI_PREMIER_7P5M_V1 = 'sg.ei.premier-7p5m.v1',
  SG_EI_PREMIER_7P5M_V2 = 'sg.ei.premier-7p5m.v2',
  SG_EI_PREMIER_SUMMARY_V1 = 'sg.ei.premier-summary.v1',
  SG_EI_PRO_100K_V1 = 'sg.ei.pro-100k.v1',
  SG_EI_PRO_100K_V2 = 'sg.ei.pro-100k.v2',
  SG_EI_PRO_1P5M_V1 = 'sg.ei.pro-1p5m.v1',
  SG_EI_PRO_1P5M_V2 = 'sg.ei.pro-1p5m.v2',
  SG_EI_PRO_2P5M_V1 = 'sg.ei.pro-2p5m.v1',
  SG_EI_PRO_2P5M_V2 = 'sg.ei.pro-2p5m.v2',
  SG_EI_PRO_2P5M_V3 = 'sg.ei.pro-2p5m.v3',
  SG_EI_PRO_300K_V1 = 'sg.ei.pro-300k.v1',
  SG_EI_PRO_300K_V2 = 'sg.ei.pro-300k.v2',
  SG_EI_PRO_700K_V1 = 'sg.ei.pro-700k.v1',
  SG_EI_PRO_700K_V2 = 'sg.ei.pro-700k.v2',
  SG_EI_PRO_SUMMARY_V1 = 'sg.ei.pro-summary.v1',
  SG_EI_SILVER_V1 = 'sg.ei.silver.v1',
  SG_EI_TRIAL_40K_V1 = 'sg.ei.trial-40k.v1',
  SG_LVS_BOOTCAMP_V1 = 'sg.lvs.bootcamp.v1',
  SG_LVS_IMPLEMENTATION_V1 = 'sg.lvs.implementation.v1',
  SG_LVS_PREMIER_INSIGHTS_V1 = 'sg.lvs.premier.insights.v1',
  SG_LVS_PRO_INSIGHTS_V1 = 'sg.lvs.pro.insights.v1',
  SG_MC_ADVANCED_100K_V1 = 'sg.mc.advanced-100k.v1',
  SG_MC_ADVANCED_10K_V1 = 'sg.mc.advanced-10k.v1',
  SG_MC_ADVANCED_10M_V1 = 'sg.mc.advanced-10m.v1',
  SG_MC_ADVANCED_1M_V1 = 'sg.mc.advanced-1m.v1',
  SG_MC_ADVANCED_200K_V1 = 'sg.mc.advanced-200k.v1',
  SG_MC_ADVANCED_20K_V1 = 'sg.mc.advanced-20k.v1',
  SG_MC_ADVANCED_500K_V1 = 'sg.mc.advanced-500k.v1',
  SG_MC_ADVANCED_50K_V1 = 'sg.mc.advanced-50k.v1',
  SG_MC_ADVANCED_5M_V1 = 'sg.mc.advanced-5m.v1',
  SG_MC_BASIC_100K_V1 = 'sg.mc.basic-100k.v1',
  SG_MC_BASIC_10K_V1 = 'sg.mc.basic-10k.v1',
  SG_MC_BASIC_10M_V1 = 'sg.mc.basic-10m.v1',
  SG_MC_BASIC_1M_V1 = 'sg.mc.basic-1m.v1',
  SG_MC_BASIC_200K_V1 = 'sg.mc.basic-200k.v1',
  SG_MC_BASIC_20K_V1 = 'sg.mc.basic-20k.v1',
  SG_MC_BASIC_500K_V1 = 'sg.mc.basic-500k.v1',
  SG_MC_BASIC_50K_V1 = 'sg.mc.basic-50k.v1',
  SG_MC_BASIC_5K_V1 = 'sg.mc.basic-5k.v1',
  SG_MC_BASIC_5M_V1 = 'sg.mc.basic-5m.v1',
  SG_MC_CLASSIC_TRIAL_DISCOUNT_V1 = 'sg.mc.classic-trial-discount.v1',
  //SG_MC_FREE_V1 = 'sg.mc.free.v1', // DON'T EVEN THINK ABOUT USING THIS
  SG_MC_FREE_TRIAL_V1 = 'sg.mc.trial.v1',
  SG_MC_END_OF_TRIAL_V1 = 'sg.mc.end-of-trial.v1',
  SG_X_CATEGORY_STATS_10K_V1 = 'sg.x.category-stats-10k.v1',
  SG_X_EASE_30_BRONZE_V1 = 'sg.x.ease-30-bronze.v1',
  SG_X_EASE_30_CUSTOM_V1 = 'sg.x.ease-30-custom.v1',
  SG_X_EASE_30_ESSENTIALS_100K_V1 = 'sg.x.ease-30-essentials-100k.v1',
  SG_X_EASE_30_ESSENTIALS_40K_V1 = 'sg.x.ease-30-essentials-40k.v1',
  SG_X_EASE_30_ESSENTIALS_50K_V1 = 'sg.x.ease-30-essentials-50k.v1',
  SG_X_EASE_30_GOLD_V1 = 'sg.x.ease-30-gold.v1',
  SG_X_EASE_30_PLATINUM_V1 = 'sg.x.ease-30-platinum.v1',
  SG_X_EASE_30_PREMIER_10M_V1 = 'sg.x.ease-30-premier-10m.v1',
  SG_X_EASE_30_PREMIER_15M_V1 = 'sg.x.ease-30-premier-15m.v1',
  SG_X_EASE_30_PREMIER_20M_V1 = 'sg.x.ease-30-premier-20m.v1',
  SG_X_EASE_30_PREMIER_25M_V1 = 'sg.x.ease-30-premier-25m.v1',
  SG_X_EASE_30_PREMIER_2P5M_V1 = 'sg.x.ease-30-premier-2p5m.v1',
  SG_X_EASE_30_PREMIER_50M_V1 = 'sg.x.ease-30-premier-50m.v1',
  SG_X_EASE_30_PREMIER_5M_V1 = 'sg.x.ease-30-premier-5m.v1',
  SG_X_EASE_30_PREMIER_7P5M_V1 = 'sg.x.ease-30-premier-7p5m.v1',
  SG_X_EASE_30_PRO_100K_V1 = 'sg.x.ease-30-pro-100k.v1',
  SG_X_EASE_30_PRO_1P5M_V1 = 'sg.x.ease-30-pro-1p5m.v1',
  SG_X_EASE_30_PRO_2P5M_V1 = 'sg.x.ease-30-pro-2p5m.v1',
  SG_X_EASE_30_PRO_300K_V1 = 'sg.x.ease-30-pro-300k.v1',
  SG_X_EASE_30_PRO_700K_V1 = 'sg.x.ease-30-pro-700k.v1',
  SG_X_EASE_30_SILVER_V1 = 'sg.x.ease-30-silver.v1',
  SG_X_EMAIL_TESTING_100_V1 = 'sg.x.email-testing-100.v1',
  SG_X_EMAIL_TESTING_30_V1 = 'sg.x.email-testing-30.v1',
  SG_X_EMAIL_TESTING_60_V1 = 'sg.x.email-testing-60.v1',
  SG_X_IP_V1 = 'sg.x.ip.v1',
  SG_X_IP_V2 = 'sg.x.ip.v2',
  SG_X_PLACEHOLDER_V1 = 'sg.x.placeholder.v1',
  SG_MC_CUSTOM_PRICING_V1 = 'sg.mc.custom-pricing.v1',
  SG_EI_CUSTOM_PRICING_V1 = 'sg.ei.custom-pricing.v1',
  SG_EI_PRO_2P5MLEGACY_V1 = 'sg.ei.pro-2p5mlegacy.v1',
  SG_X_EMAIL_TESTING_0_V1 = 'no-additional-email-testing-credits',
}
