import { ActionTypes } from './actionTypes';
import { Action } from 'src/state/types/action';
import { AccountOfferings, UpdateOffering } from './actions';
import { mapEmailApiPlan } from '../products/adapters/emailApiPlans';
import { mapMarketingCampaignsPlan } from '../products/adapters/marketingCampaignsPlans';

const initialState: AccountOfferings = {
  catalog: [],
  emailApi: {
    current: undefined,
    future: undefined,
  },
  marketingCampaigns: {
    current: undefined,
    future: undefined,
  },
};

interface GetAccountOfferingsAction extends Action {
  type: ActionTypes.GetAccountOfferingsSuccess;
  payload: AccountOfferings;
}

interface UpdateEmailApiOfferingAction extends Action {
  type: ActionTypes.UpdateEmailApiOffering;
  payload: UpdateOffering;
}

interface UpdateMarketingCampaignsOfferingAction extends Action {
  type: ActionTypes.UpdateMarketingCampaignsOffering;
  payload: UpdateOffering;
}

export type AccountOfferingsAction =
  | GetAccountOfferingsAction
  | UpdateEmailApiOfferingAction
  | UpdateMarketingCampaignsOfferingAction;

export const accountOfferings = (
  state = initialState,
  action: AccountOfferingsAction
): AccountOfferings => {
  switch (action.type) {
    case ActionTypes.GetAccountOfferingsSuccess:
      return { ...action.payload };
    case ActionTypes.UpdateEmailApiOffering: {
      const currentOffering = state.catalog.find(
        (offering) => offering.name === action.payload.currentOffering
      );

      if (!currentOffering) {
        // All accounts must be on an offering
        return state;
      }

      const futureOffering = state.catalog.find(
        (offering) => offering.name === action.payload.futureOffering
      );

      return {
        ...state,
        emailApi: {
          current: {
            ...mapEmailApiPlan(currentOffering),
            startDate: action.payload.currentOfferingStartDate,
          },
          future: futureOffering
            ? {
                ...mapEmailApiPlan(futureOffering),
                startDate: action.payload.currentOfferingStartDate,
              }
            : undefined,
        },
      };
    }
    case ActionTypes.UpdateMarketingCampaignsOffering: {
      const currentOffering = state.catalog.find(
        (offering) => offering.name === action.payload.currentOffering
      );

      if (!currentOffering) {
        // All accounts must be on an offering
        return state;
      }

      const futureOffering = state.catalog.find(
        (offering) => offering.name === action.payload.futureOffering
      );

      return {
        ...state,
        marketingCampaigns: {
          current: {
            ...mapMarketingCampaignsPlan(currentOffering),
            startDate: action.payload.currentOfferingStartDate,
          },
          future: futureOffering
            ? {
                ...mapMarketingCampaignsPlan(futureOffering),
                startDate: action.payload.currentOfferingStartDate,
              }
            : undefined,
        },
      };
    }
    default:
      return state;
  }
};
