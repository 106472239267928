import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { legacyBillingStoreConnect } from 'src/state/store';
import { MakoStateType } from 'src/state/types/makoStateType';
import * as AccountOfferingActions from 'src/state/middle_tier/account_offerings/actions';
import { Props } from './Navbar';
import { showFreeTrialsInConsole } from 'src/helpers/featureFlags/showFreeTrialsInConsole';

const getLoading = (loading: MakoStateType['loading']) => {
  return Object.values(loading).some((isLoading) => isLoading);
};

export const mapStateToProps = (state: MakoStateType) => {
  const { accountOfferings } = state;
  return {
    loading: getLoading(state.loading),
    emailApiPlan: accountOfferings?.emailApi?.current,
    marketingCampaignsPlan: accountOfferings?.marketingCampaigns?.current,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAccountOfferings: () => {
      dispatch(AccountOfferingActions.Actions.getAccountOfferings());
    },
  };
};

export type GlobalNavbarConnectorProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  Props;

export function withGlobalBannerConnector(Component: React.FC<Props>) {
  const GlobalBannerConnector: React.FC<GlobalNavbarConnectorProps> = (
    props
  ) => {
    useEffect(() => {
      if (showFreeTrialsInConsole()) {
        props.getAccountOfferings();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Component {...props} />;
  };

  return legacyBillingStoreConnect(
    mapStateToProps,
    mapDispatchToProps
  )(GlobalBannerConnector);
}
