import moment from 'moment';

import { CacheKeys as Keys } from './keys';

// NOTE: See ./Readme.md for documentation

const PREFIX = 'mako__';

export const Cache = {
  // private functions
  getStorage: (key: string) => {
    const cache = localStorage.getItem(key);
    return cache ? JSON.parse(cache) : cache;
  },

  getStorageData: <T>(key: string) => {
    const cache = Cache.getStorage(key);
    return cache ? (cache.response as T) : cache;
  },

  deleteStorageData: (key: string) => {
    return localStorage.removeItem(key);
  },

  deleteStorageIfStale: <T>(
    key: string,
    response: T,
    shouldDeleteCachedObject: (cacheObj: T, response: T) => boolean
  ) => {
    const cache = Cache.getStorage(key);
    // If stored data exists
    if (cache) {
      const isExpired = cache.expiration < moment().unix();
      // if it is expired or if the object in storage matches the response object
      if (isExpired || shouldDeleteCachedObject(cache.response, response)) {
        return Cache.deleteStorageData(key);
      }
    }
  },

  getMostRecentData: <T>(key: string, response: T) => {
    const stored = Cache.getStorageData(key);
    return stored || response;
  },

  createKey: (userId: number, key: Keys): string =>
    `${PREFIX}${userId}__${key}`,

  // public functions
  resolveOptimism: <T>(
    userId: number,
    key: Keys,
    response: T,
    shouldDeleteCachedObject: (cacheObj: T, response: T) => boolean
  ) => {
    const cacheKey = Cache.createKey(userId, key);
    Cache.deleteStorageIfStale(cacheKey, response, shouldDeleteCachedObject);
    return Cache.getMostRecentData(cacheKey, response);
  },

  resolveIfNotStale: <T>(userId: number, key: Keys): T | null => {
    const cacheKey = Cache.createKey(userId, key);
    const cache = Cache.getStorage(cacheKey);
    if (cache && cache.expiration > moment().unix()) {
      return cache as T;
    }
    return null;
  },

  setStorageData: <T>(userId: number, key: Keys, data: T) => {
    const storedData = {
      expiration: moment().add(10, 'minutes').unix(),
      response: data,
    };

    return localStorage.setItem(
      Cache.createKey(userId, key),
      JSON.stringify(storedData)
    );
  },
};
